import React, { useEffect } from 'react'

const Layout = React.memo(({ children, title }) => {
    useEffect(() => {
        if (title) {
            document.title = 'Vizual Records - ' + title
        } else {
            document.title = 'Vizual Records'
        }
    }, [title])
    return (
        <div className="container">
            <div className="content">
                <div className="inner-content wrap pt-6 pb-16 px-6 sm:px-4 lg:px-0">
                    <main
                        className="main"
                        role="main"
                        itemScope
                        itemProp="mainContentOfPage"
                    >
                        {children}
                    </main>
                </div>
            </div>
        </div>
    )
})

export default Layout
