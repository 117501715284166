import React from 'react'
import { Link } from 'react-router-dom'

import AlbumsIcon from '../Icons/AlbumsIcon'
import ArtistIcon from '../Icons/ArtistIcon'
import VizualIcon from '../Icons/VizualIcon'

const Intro = React.memo(() => {
    return (
        <div className="intro py-8 px-2 md:px-0 bg-gray-800 w-full flex items-center">
            <div className="inner-intro wrap container flex flex-col sm:flex-row justify-between items-center">
                <p className="text-gray-100 mb-6 w-3/4 sm:w-7/12 sm:mb-0">
                    Welcome to Vizual Records! Listen to and download Vizual
                    music from the{' '}
                    <a className="text-sky-500" href="/catalog/">
                        Vizual Records Catalog
                    </a>{' '}
                    featuring Joshua Iz, Iz & Diz, Derrick Carter, Chez Damier,
                    Aiby & The Noise, Rubba J and more. See more from{' '}
                    <a className="text-blue-500" href="/artists/">
                        Vizual Artists
                    </a>
                    . Now with full-quality AIFF audio.
                </p>
                <div className="">
                    <ul className="nostyle flex items-end justify-center flex-row sm:space-x-5 space-x-8">
                        <li>
                            <Link to="/catalog">
                                <div className="flex items-center justify-end flex-col">
                                    <div className="h-8">
                                        <AlbumsIcon />
                                    </div>
                                    Catalog
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/artists">
                                <div className="flex items-center justify-end flex-col">
                                    <div className="h-8">
                                        <ArtistIcon />
                                    </div>
                                    Artists
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/about">
                                <div className="flex items-center justify-end flex-col">
                                    <div className="h-8 vizual-icon-wrap">
                                        <VizualIcon />
                                    </div>
                                    About
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
})

export default Intro
