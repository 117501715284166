import React from 'react'

import RecordIcon from '../Icons/RecordIcon'

const Vinyl = () => {
    return (
        <div
            id="vinyl"
            className="vinyl store-section pt-2 border-t border-gray-200 pb-5"
        >
            <div className="vinyl-header section-header">
                <h2 className="section-title text-3xl font-bold mt-2 mb-4 flex items-center tracking-tight">
                    <span className="record-icon w-6 h-6 mr-1 mt-2">
                        <RecordIcon />
                    </span>
                    Vinyl
                </h2>
            </div>
            <div className="section-content">
                <h3>Vizual Vinyl coming soon.</h3>
            </div>
        </div>
    )
}

export default Vinyl
