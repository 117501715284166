import React from 'react'
import Layout from '../Layout/Layout'
import CircleAnimation from './CircleAnimation'

import './404.scss'

const PageNotFound = () => {
    return (
        <Layout>
            <div className="404 not-found my-4 page-404">
                <div className="page-outer flex justify-center m-auto mt-2">
                    <div className="page-inner w-full md:w-7/12">
                        <h1 className="page-title text-4xl font-bold">
                            404 - Not found.
                        </h1>
                        <h3>
                            We're sorry. The item you are looking for cannot be
                            found.
                        </h3>
                        <div className="404-image">
                            <CircleAnimation />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PageNotFound
