import React, { useEffect } from 'react'

const MAX_TRANSLATE = 7

const CircleAnimation = () => {
    useEffect(() => {
        let elements = [
            'background',
            'mountain6',
            'mountain5',
            'mountain4',
            'mountain3',
            'mountain2',
            'mountain1',
            'star1',
            'star2',
            'star3',
            'moon',
            'viz-logo',
        ].map((klass) => document.querySelector(`.${klass}`))

        document.addEventListener('mousemove', (event) => {
            const height = event.clientY / window.innerHeight
            const width = event.clientX / window.innerWidth
            updatePositions(height, width)
        })

        window.addEventListener(
            'deviceorientation',
            (event) => {
                const height = -event.beta / 90
                const width = event.gamma / 180
                updatePositions(height, width)
            },
            true
        )

        function updatePositions(height, width) {
            const maxVHTranslation = -height * MAX_TRANSLATE
            const maxVWTranslation = -width * MAX_TRANSLATE
            const length = elements.length
            for (let i = 0; i < elements.length; i++) {
                const translateVH = (i / length) * maxVHTranslation
                const translateVW = (i / length) * maxVWTranslation

                elements[
                    i
                ].style.transform = `translate3d(${translateVW}vh, ${translateVH}vh, 0)`
            }
        }
    })

    return (
        <div className="image-container relative rounded-full overflow-hidden">
            <img
                className="background absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/bg_midnight.svg"
                alt=""
            />
            <img
                className="mountain6 absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/mountain6_circle.svg"
                alt=""
            />
            <img
                className="mountain5 absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/mountain5_circle.svg"
                alt=""
            />
            <img
                className="mountain4 absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/mountain4_circle.svg"
                alt=""
            />
            <img
                className="mountain3 absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/mountain3_circle.svg"
                alt=""
            />
            <img
                className="mountain2 absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/mountain2_circle.svg"
                alt=""
            />
            <img
                className="mountain1 absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/mountain1_circle.svg"
                alt=""
            />
            <img
                className="star1 absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/star_large_circle.svg"
                alt=""
            />
            <img
                className="star2 absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/star_medium_circle.svg"
                alt=""
            />
            <img
                className="star3 absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/star_small_circle.svg"
                alt=""
            />
            <img
                className="moon absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/moon_circle.svg"
                alt=""
            />
            <img
                className="viz-logo absolute"
                src="https://vizualassets.s3.us-east-2.amazonaws.com/vizual_logo_circle.svg"
                alt=""
            />
        </div>
    )
}

export default CircleAnimation
