import React, { useState, useEffect, useCallback } from 'react'
import Modali, { useModali } from 'modali'
import 'balloon-css'
import parse from 'html-react-parser'

import ExternalLinkIcon from '../Icons/ExternalLinkIcon'
import { SIZE_ARRAY, slugify } from '../../helpers/helpers'

const THREADLESS_URL = process.env.REACT_APP_THREADLESS_URL

const ClothingItem = React.memo(({ item }) => {
    const [colorset, setColorset] = useState()
    const [exampleModal, toggleExampleModal] = useModali()

    let fields = item.clothingFields
    let clothingTypes = fields.clothingType
    let defaultTypes = fields.defaultType

    // console.log('item in ClothingItem', item)

    // console.log('defaultTypes', defaultTypes)

    let design = fields.design ? fields.design : ''

    let defaultColor = fields.defaultColorName
        ? fields.defaultColorName
        : 'Black'
    let defaultHex = fields.defaultColorHex ? fields.defaultColorHex : '000000'
    let defaultType
    let defaultSlug
    let defaultGender
    let defaultStyle
    let defaultFit
    let defaultDesc
    let defaultPrice
    let defaultImage

    if (defaultTypes && defaultTypes.length) {
        defaultType =
            defaultTypes[0].name +
            ' ' +
            defaultTypes[2].name +
            ' ' +
            defaultTypes[1].name

        defaultSlug =
            defaultTypes[0].slug +
            '-' +
            defaultTypes[2].slug +
            '-' +
            defaultTypes[1].slug

        defaultGender = defaultTypes[0].slug
        defaultStyle = defaultTypes[1].slug
        if (defaultStyle === 'tank-womens') {
            defaultStyle = 'tank'
        }
        defaultFit = defaultTypes[2].slug
        defaultDesc = defaultTypes[2].description
            ? defaultTypes[2].description
            : defaultTypes[1].description

        defaultPrice = defaultTypes[2].clothingTypeFields.price
            ? defaultTypes[2].clothingTypeFields.price
            : defaultTypes[1].clothingTypeFields.price

        defaultImage =
            (defaultTypes[2].clothingTypeFields.blankImage &&
                defaultTypes[2].clothingTypeFields.blankImage.sourceUrl) ||
            null
    }

    const [currentStyle, setCurrentStyle] = useState({
        name: defaultType ? defaultType : '',
        clothingType: defaultType ? defaultType : '',
        clothingTypeSlug: defaultSlug,
        gender: defaultGender,
        style: defaultStyle,
        fit: defaultFit,
        design: item.slug,
        designImage: design.mediaItemUrl ? design.mediaItemUrl : null,
        size: '',
        color: defaultColor,
        hex: defaultHex,
        price: defaultPrice ? defaultPrice : '20.00',
        desc: defaultDesc ? defaultDesc : '',
        colorSet: fields.defaultColorset ? fields.defaultColorset : 'colorset1',
        image: {
            src: defaultImage,
            alt: defaultType,
        },
    })

    let clothingType
    let clothingOptions
    let currentColor

    const handleOptionChange = useCallback(
        (e) => {
            e.persist()

            // console.log('e', e.target)
            // console.log('e', e.target.value)

            // get data attributes from selected option

            let currentBlank =
                e.target[e.target.selectedIndex].getAttribute('data-blank')

            // let currentName = e.target[e.target.selectedIndex].getAttribute(
            //     'data-name'
            // )
            let currentSlug =
                e.target[e.target.selectedIndex].getAttribute('data-slug')

            let currentFit =
                e.target[e.target.selectedIndex].getAttribute('data-fit')

            if (currentFit && currentFit.indexOf('baseball') >= 0) {
                currentFit = 'baseball-triblend'
            }

            let currentStyleSlug =
                e.target[e.target.selectedIndex].getAttribute('data-style')

            if (currentStyleSlug === 'tank-womens') {
                currentStyleSlug = 'tank'
            }

            let currentColorset =
                e.target[e.target.selectedIndex].getAttribute('data-colorset')

            let currentGender =
                e.target[e.target.selectedIndex].getAttribute('data-gender')

            // let currentStyle = e.target[e.target.selectedIndex].getAttribute(
            //     'data-style'
            // )

            let currentDesc =
                e.target[e.target.selectedIndex].getAttribute('data-desc')

            let currentPrice =
                e.target[e.target.selectedIndex].getAttribute('data-price')

            // assign new colorset
            let newColorset = fields[currentColorset]

            // console.log('currentName, currentSlug, currentFit, currentStyleSlug, currentStyle', currentName, currentSlug, currentFit, currentStyleSlug, currentStyle)

            // update current photo to be from colorset
            if (newColorset) {
                setCurrentStyle((currentStyle) => ({
                    ...currentStyle,
                    name: e.target.value,
                    clothingType: e.target.value,
                    clothingTypeSlug: currentSlug,
                    style: currentStyleSlug,
                    colorSet: currentColorset,
                    gender: currentGender,
                    fit: currentFit,
                    desc: currentDesc,
                    price: currentPrice,
                    color: 'Black',
                    hex: '000000',
                    image: {
                        src: currentBlank ? currentBlank : defaultImage,
                        alt: e.target.value + ' ' + currentFit,
                    },
                }))
            }
        },
        // eslint-disable-next-line
        [currentStyle, fields, defaultImage]
    )

    const handleColorChange = (e) => {
        // get color attribute from selected color
        currentColor = e.target.dataset.color
        let el = e.target

        // console.log('el color', el.dataset.colorname)

        updateColorSwatches(el)

        setCurrentStyle({
            ...currentStyle,
            color: el.dataset.colorname,
            hex: currentColor,
        })
    }

    const updateColorSwatches = (el) => {
        let colorsList = el.closest('.colors-list')

        let colors = colorsList.querySelectorAll('.color')

        colors.forEach((c) => {
            c.classList.remove(
                'border-4',
                'border-blue-600',
                'shadow-md',
                'border'
            )
            c.classList.add('border')
        })

        el.classList.remove('border')
        el.classList.add('border-4', 'border-blue-600', 'shadow-md')

        return null
    }

    const handleSizeSelect = (e) => {
        // console.log('e', e.target)

        // get size data attribute from selected size
        let currentSize = e.target.dataset.size
        let el = e.target

        let sizes = document.querySelectorAll('.clothing-size')

        // if size is already selected, disable it
        if (currentStyle.size === currentSize) {
            setCurrentStyle({
                ...currentStyle,
                size: '',
            })

            sizes.forEach((s) => {
                s.classList.remove(
                    'border-2',
                    'border-blue-600',
                    'shadow-md',
                    'bg-gray-700',
                    'text-gray-200'
                )
            })

            return null
        }

        sizes.forEach((s) => {
            s.classList.remove(
                'border-2',
                'border-blue-600',
                'shadow-md',
                'bg-gray-700',
                'text-gray-200'
            )
        })

        el.classList.add(
            'border-2',
            'border-blue-600',
            'shadow-md',
            'bg-gray-700',
            'text-gray-200'
        )

        setCurrentStyle({
            ...currentStyle,
            size: currentSize,
        })

        // console.log('currentSize', currentSize)

        return null
    }

    // console.log('clothingTypes', clothingTypes)
    let clothingTypesWithColorsets = clothingTypes.filter((type, index) => {
        if (
            type &&
            type.clothingTypeFields &&
            type.clothingTypeFields.colorset
        ) {
            return type
        }

        return null
    })

    // console.log('clothingTypesWithColorsets', clothingTypesWithColorsets)

    clothingOptions = clothingTypesWithColorsets.map((t, i) => {
        // console.log('t', t)
        let ancestors = t.ancestors.nodes

        // if ancestor has no ancestor, it's the grandparent
        let grandparent = ancestors.filter((a, i) => {
            if (a.ancestors === null) {
                return a
            }
            return null
        })

        // if ancestor has 1 ancestor, it's the parent
        let parent = ancestors.filter((a, i) => {
            if (a.ancestors && a.ancestors.nodes.length === 1) {
                return a
            }
            return null
        })

        clothingType = grandparent[0].name + ' ' + t.name + ' ' + parent[0].name
        // console.log('clothingType', clothingType)

        let currentStyle

        let currentGender = grandparent[0].slug

        let textArray = parent[0].slug.split('-')

        // fix for longsleeve for url
        if (
            textArray.length === 3 &&
            textArray.find((a) => a.includes('longsleeve'))
        ) {
            // console.log(textArray.join('-').trim())
            currentStyle = textArray.join('-').trim()
        } else {
            currentStyle = parent[0].slug.split('-').slice(0, 2).join('-')
        }
        let currentPrice = t.clothingTypeFields.price

        let currentBlank = t.clothingTypeFields.blankImage
            ? t.clothingTypeFields.blankImage.sourceUrl
            : null

        // console.log('currentGender, currentStyle', currentGender, currentStyle)

        // console.log('parent, grandparent', parent, grandparent)

        return (
            <option
                className={`term mr-2 ${t.slug && t.slug}`}
                data-name={t.name}
                data-slug={t.slug}
                data-gender={
                    currentGender && currentGender ? currentGender : 'mens'
                }
                data-style={currentStyle}
                data-desc={t.description}
                data-price={currentPrice}
                data-fit={slugify(t.name)}
                data-blank={currentBlank}
                data-colorset={t.clothingTypeFields.colorset}
                key={`${t.name}-${t.id}`}
            >
                {clothingType && clothingType}
            </option>
        )
    })

    const sizes = SIZE_ARRAY.map((size, i) => {
        return (
            <li
                key={`${size.sizeSlug}-${currentStyle.name}`}
                className="clothing-size flex items-center justify-center px-2 py-1 text-base border border-gray-800 rounded-sm hover:bg-gray-800 hover:text-gray-200 cursor-default"
                data-size={size.sizeSlug}
                onClick={(e) => handleSizeSelect(e)}
            >
                {size.size}
            </li>
        )
    })

    useEffect(() => {
        let matchingColorset
        if (currentStyle && currentStyle.colorSet) {
            let currentColorset = currentStyle.colorSet
            let matching = fields[currentColorset]

            // console.log('currentStyle.colorSet', currentStyle.colorSet)

            if (matching) {
                // console.log('matching true', matching)
                matchingColorset = matching
                    .map((color, index) => {
                        // console.log('color', color)
                        const [colorHex, colorName] = color.split('_')
                        const colorStyle = {
                            backgroundColor: '#' + colorHex,
                        }

                        let colorNiceName = colorName.replace(/-/g, ' ')

                        return (
                            <li
                                className="clothing-color"
                                key={`${color}-${currentStyle.colorSet}-${index}`}
                            >
                                <span
                                    aria-label={colorNiceName}
                                    label={colorNiceName}
                                    data-colorname={colorNiceName}
                                    data-balloon-pos="up"
                                    className={`block w-5 h-5 rounded-full ${
                                        currentStyle.hex !== colorHex
                                            ? 'border'
                                            : null
                                    } border-gray-400 color ${colorHex} color-${colorHex} ${
                                        currentStyle.gender
                                            ? currentStyle.gender +
                                              '-' +
                                              colorHex
                                            : ''
                                    } ${
                                        currentStyle.hex === colorHex
                                            ? 'border-4 border-blue-600 selected-color'
                                            : null
                                    }`}
                                    style={colorStyle}
                                    data-color={colorHex}
                                    onClick={(e) => handleColorChange(e)}
                                >
                                    {null}
                                </span>
                            </li>
                        )
                    })
                    .sort(function (a, b) {
                        if (
                            a.props.children.props.label <
                            b.props.children.props.label
                        ) {
                            return -1
                        }
                        if (
                            a.props.children.props.label >
                            b.props.children.props.label
                        ) {
                            return 1
                        }
                        return 0
                    })

                // console.log('matchingColorset', matchingColorset)
                setColorset(matchingColorset)
            }
        }
        // eslint-disable-next-line
    }, [currentStyle])

    // console.log('currentStyle', currentStyle)

    useEffect(() => {
        setCurrentStyle(currentStyle)
        // console.log('currentStyle', currentStyle)
    }, [setCurrentStyle, currentStyle, handleOptionChange])

    return (
        <li
            className={`overflow-visible clothing-item item-${currentStyle.clothingTypeSlug} item-${currentStyle.design}`}
        >
            <div
                onClick={toggleExampleModal}
                className={`clothing-image cursor-pointer relative image-${currentStyle.clothingTypeSlug}-${currentStyle.design} image-${currentStyle.design}`}
            >
                <img
                    className="clothing-img"
                    src={currentStyle && currentStyle.image.src}
                    alt={parse(item.title) + ' in ' + currentStyle.color}
                    style={{
                        backgroundColor: '#' + currentStyle.hex,
                    }}
                />
                <img
                    src={currentStyle.designImage}
                    alt={currentStyle.design}
                    className="design-image absolute block"
                />
            </div>
            <Modali.Modal {...exampleModal}>
                <div
                    className={`clothing-image cursor-pointer relative image-${currentStyle.clothingTypeSlug}-${currentStyle.design} image-${currentStyle.design}`}
                >
                    <img
                        className="clothing-img"
                        src={currentStyle && currentStyle.image.src}
                        alt={parse(item.title) + ' in ' + currentStyle.color}
                        style={{
                            backgroundColor: '#' + currentStyle.hex,
                        }}
                    />
                    <img
                        src={currentStyle.designImage}
                        alt=""
                        className="design-image absolute block"
                    />
                    <div className="image-caption modal-caption text-base font-bold text-gray-900 ml-4 mb-3 flex">
                        <span>{parse(item.title)}</span>
                        &nbsp;
                        <span className="text-gray-500 block ml-auto mr-4 font-semibold">
                            {currentStyle.clothingType &&
                                currentStyle.clothingType +
                                    ' in ' +
                                    currentStyle.color}
                        </span>
                    </div>
                </div>
            </Modali.Modal>
            <h3 className="clothing-title text-lg text-center font-bold my-2">
                {parse(item.title)}
            </h3>
            <p className="text-xs text-gray-500 my-1">Select your fit:</p>
            <div className="inline-block relative w-full">
                <select
                    onChange={(e) => handleOptionChange(e)}
                    className="terms-select block appearance-none text-sm w-full bg-white border border-gray-400 hover:border-gray-500 px-2 py-2 pr-6 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                >
                    {defaultTypes && defaultTypes.length && (
                        <option
                            className="term mr-2"
                            data-name={
                                defaultTypes[2].name && defaultTypes[2].name
                            }
                            data-slug={
                                defaultTypes[2].slug && defaultTypes[2].slug
                            }
                            data-desc={
                                defaultTypes[2].desc ? defaultTypes[2].desc : ''
                            }
                            data-colorset={
                                fields.defaultColorset && fields.defaultColorset
                            }
                            data-gender={
                                defaultTypes[0].slug && defaultTypes[0].slug
                            }
                            data-style={
                                defaultTypes[1].slug && defaultTypes[1].slug
                            }
                            key={`${defaultTypes[2].name}-${defaultTypes[2].id}`}
                        >
                            {defaultType && defaultType}
                        </option>
                    )}
                    {clothingOptions && clothingOptions}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                </div>
            </div>
            <div className="clothing-colors py-4 my-2 flex justify-center pr-2">
                <ul className="colors-list nostyle grid grid-cols-12 gap-1">
                    {colorset && colorset}
                </ul>
            </div>
            <div className="clothing-size-outer">
                <ul className="clothing-sizes grid grid-cols-5 gap-2 text-gray-700">
                    {sizes && sizes}
                </ul>
            </div>
            <div className="clothing-price-outer">
                <p className="text-xl text-blue-800 my-3 font-bold tracking-tighter">
                    {currentStyle.price ? `$${currentStyle.price}` : '$20.00'}
                </p>
            </div>
            <div
                className="buy-button"
                aria-label={currentStyle.size ? '' : 'Please select a size'}
                data-balloon-pos={currentStyle.size ? null : 'up'}
            >
                <a
                    href={`${THREADLESS_URL}${item.slug}/${
                        currentStyle.gender ? currentStyle.gender : 'mens'
                    }/${currentStyle.style ? currentStyle.style : 't-shirt'}/${
                        currentStyle.fit ? currentStyle.fit : 'regular'
                    }${
                        currentStyle.color
                            ? '?color=' +
                              currentStyle.color
                                  .replace(/\s+/g, '_')
                                  .toLowerCase()
                            : null
                    }${
                        currentStyle.size ? '&size=' + currentStyle.size : null
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`button bg-sky-500 hover:bg-sky-700 pb-0 font-bold rounded mt-3 w-full flex items-center justify-center text-white border-0 ${
                        currentStyle.size ? 'enabled' : 'cursor-not-allowed'
                    }`}
                >
                    Buy On Threadless <ExternalLinkIcon />
                </a>
            </div>
            <div className="clothing-description my-4">
                <p className="text-sm text-gray-600 leading-tight">
                    {currentStyle.desc
                        ? currentStyle.desc
                        : 'A very nice Vizual garment you will cherish.'}
                </p>
            </div>
        </li>
    )
})

export default ClothingItem
