import React from 'react'

const RecordIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="svg-inline--fa fa-record-vinyl fa-w-16 w-5"
            data-icon="record-vinyl"
            data-prefix="fad"
            viewBox="0 0 512 512"
        >
            <g className="fa-group">
                <path
                    fill="currentColor"
                    d="M256 128a128 128 0 10128 128 128 128 0 00-128-128zm0 152a24 24 0 1124-24 24 24 0 01-24 24z"
                    className="fa-secondary"
                    opacity="0.4"
                ></path>
                <path
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 376a128 128 0 11128-128 128 128 0 01-128 128z"
                    className="fa-primary"
                    opacity="0.65"
                ></path>
            </g>
        </svg>
    )
}

export default React.memo(RecordIcon)
