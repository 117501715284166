import React from 'react'

const ClothingInfo = () => {
    return (
        <div className="clothing-info my-4 text-sm text-gray-700 pt-6 px-8 pb-4 bg-stone-100 lg:w-1/2 rounded">
            <h3 className="text-lg text-gray-800 font-bold mb-1">
                About Vizual Clothing
            </h3>
            <p>
                Select your style, color, and size and then click the 'Buy on
                Threadless' button to complete your purchase on the Threadless{' '}
                <a
                    href="https://vizual.threadless.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Vizual Artist Shop
                </a>
                . Your selections will automagically be carried over! All you
                have to do is click 'Add To Cart' on Threadless and checkout.
                Boom.
            </p>
            <p>
                <em>
                    <strong>Note:</strong> all fulfillment is handled by
                    Threadless. Prices and availability are subject to change.
                </em>{' '}
                Check the{' '}
                <a
                    href="https://vizual.threadless.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Vizual Artist Shop
                </a>{' '}
                for full range of products, styles, fits, colors, including Kids
                and accessories.
            </p>
        </div>
    )
}

export default ClothingInfo
