import React from 'react'

const VizualIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 300"
            className="vizual-icon w-8 px-1"
        >
            <g fill="#08c">
                <path d="M235.5 195.5l-12-6.5c1.3-2.4 2.5-4.9 3.5-7.3 1.1-2.5 2-5.1 2.8-7.7s1.5-5.3 2-8c.6-2.7 1-5.5 1.3-8.3s.4-5.7.4-8.5-.1-5.7-.4-8.5-.7-5.6-1.3-8.3-1.2-5.4-2-8-1.7-5.1-2.8-7.6c-1-2.5-2.2-4.9-3.5-7.3l12-6.5c1.5 2.8 2.9 5.7 4.1 8.5 1.2 2.9 2.3 5.9 3.3 8.9.9 3 1.7 6.2 2.4 9.3.6 3.1 1.1 6.4 1.5 9.6.3 3.3.5 6.6.5 9.9s-.2 6.6-.5 9.9-.8 6.5-1.5 9.6c-.6 3.1-1.4 6.3-2.4 9.3-.9 3-2 6-3.3 8.9-1.2 2.9-2.6 5.8-4.1 8.6zm-170.7 0c-1.5-2.8-2.9-5.6-4.1-8.5s-2.3-5.9-3.3-8.9c-.9-3-1.7-6.1-2.4-9.3s-1.1-6.4-1.5-9.7c-.3-3.2-.5-6.6-.5-9.9s.2-6.7.5-9.9.8-6.4 1.5-9.6c.6-3.2 1.5-6.3 2.4-9.3s2-6 3.3-8.9c1.2-2.9 2.6-5.8 4.1-8.5l12 6.5c-1.3 2.3-2.5 4.8-3.5 7.3s-2 5.1-2.8 7.7-1.5 5.3-2.1 8-1 5.5-1.3 8.3-.4 5.7-.4 8.5c0 2.9.1 5.7.4 8.5s.7 5.6 1.3 8.3 1.3 5.4 2.1 8 1.8 5.2 2.8 7.7 2.2 4.9 3.5 7.3zm85.6 1.6c-26.2 0-47.5-21.3-47.5-47.5s21.3-47.5 47.5-47.5 47.5 21.3 47.5 47.5-21.3 47.5-47.5 47.5zm0-81.5c-18.7 0-33.9 15.2-33.9 33.9s15.2 33.9 33.9 33.9 33.9-15.2 33.9-33.9c.1-18.6-15.2-33.9-33.9-33.9z"></path>
                <path d="M150.4 172.7c-12.7 0-23.1-10.4-23.1-23.1s10.4-23.1 23.1-23.1 23.1 10.4 23.1 23.1-10.3 23.1-23.1 23.1zm0-32.6c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5c0-5.3-4.2-9.5-9.5-9.5z"></path>
                <path d="M150.4 221.5c-39.6 0-71.9-32.2-71.9-71.9 0-39.6 32.2-71.9 71.9-71.9 39.6 0 71.9 32.2 71.9 71.9 0 39.6-32.2 71.9-71.9 71.9zm0-130.2c-32.1 0-58.3 26.2-58.3 58.3s26.2 58.3 58.3 58.3c32.2 0 58.3-26.2 58.3-58.3 0-32.2-26.1-58.3-58.3-58.3z"></path>
                <path d="M289.5 149.3s-52-71.7-139.1-71.7c-87 0-139.2 71.7-139.2 71.7H29s47.4-58.2 121.3-58.2 121.3 58.2 121.3 58.2zm0 0s-52 71.7-139.1 71.7c-87 0-139.2-71.7-139.2-71.7H29s47.4 58.2 121.3 58.2 121.3-58.2 121.3-58.2z"></path>
            </g>
        </svg>
    )
}

export default React.memo(VizualIcon)
