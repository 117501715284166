import React, { useState, useEffect, Fragment } from 'react'

import Meta from '../Meta/Meta'
import Layout from '../Layout/Layout'
import Product from '../Product/Product'
import Loading from '../Loading/Loading'
import ScrollToTop from '../ScrollToTop/ScrollToTop'

const meta = {
    title: 'Catalog',
    num: 0,
    slug: 'catalog',
}

const Catalog = React.memo(({ products }) => {
    console.log('products', products)
    if (!products) {
        return (
            <Layout title="Catalog">
                <div className="catalog">
                    <div className="page-header flex">
                        <h1 className="page-title text-4xl font-bold mt-2 mb-4">
                            Catalog
                        </h1>
                    </div>
                    <Loading />
                </div>
            </Layout>
        )
    }

    const [productData, setProductData] = useState([])
    const [sortType, setSortType] = useState('catalogNumberASC')

    useEffect(() => {
        let sortProperty
        let sorted

        // sort products by dropdown choice
        const sortArray = (type) => {
            const types = {
                catalogNumberASC: 'node.productFields.cat',
                catalogNumberDESC: '-node.productFields.cat',
                releaseDateDesc: '-node.productFields.releaseDate',
                releaseDateAsc: 'node.productFields.releaseDate',
                releaseNameAZ: 'node.productFields.releaseTitle',
                releaseNameZA: '-node.productFields.releaseTitle',
                artistNameAZ: 'node.productFields.artist',
                artistNameZA: '-node.productFields.artist',
                albumsOnly: 'albumsOnly',
                epsOnly: 'epsOnly',
            }
            sortProperty = types[type]

            console.log('sortProperty', sortProperty)

            // use sortBy function (below) for nested properties
            if (products && products.length > 0) {
                if (sortProperty === 'albumsOnly') {
                    sorted = products.filter((p) => {
                        return (
                            p.node.productCategories.nodes[0].name === 'Album'
                        )
                    })
                } else if (sortProperty === 'epsOnly') {
                    sorted = products.filter((p) => {
                        return p.node.productCategories.nodes[0].name === 'EP'
                    })
                } else {
                    sorted = [...products].sortBy(sortProperty)
                }

                // set list data for display
                setProductData(sorted)
            }
        }

        sortArray(sortType)
    }, [sortType, products])

    return (
        <Fragment>
            <Meta meta={meta} />
            <Layout title="Catalog">
                <div className="catalog">
                    <div className="page-header flex flex-col sm:flex-row">
                        <h1 className="page-title text-4xl font-bold mt-2 mb-4">
                            Catalog
                        </h1>
                        <div className="catalog-select flex items-center sm:ml-auto ml-0 mb-4 sm:mb-0">
                            <p className="mb-0 mr-1 w-20">Sort by:</p>
                            <div className="inline-block relative w-full mr-1">
                                <select
                                    onChange={(e) =>
                                        setSortType(e.target.value)
                                    }
                                    className="terms-select block appearance-none text-sm w-full bg-white border border-gray-400 hover:border-gray-500 px-2 py-2 pr-6 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option
                                        value="catalogNumberASC"
                                        id="catalog-number-asc"
                                    >
                                        Catalog Number (ASC)
                                    </option>
                                    <option
                                        value="catalogNumberDESC"
                                        id="catalog-number-desc"
                                    >
                                        Catalog Number (DESC)
                                    </option>
                                    <option
                                        value="releaseDateDesc"
                                        id="release-date-desc"
                                    >
                                        Release Date (newest first)
                                    </option>
                                    <option
                                        value="releaseDateAsc"
                                        id="release-date-asc"
                                    >
                                        Release Date (oldest first)
                                    </option>
                                    <option
                                        value="releaseNameAZ"
                                        id="release-name-a-z"
                                    >
                                        Release Title (A–Z)
                                    </option>
                                    <option
                                        value="releaseNameZA"
                                        id="release-name-z-z"
                                    >
                                        Release Title (Z–A)
                                    </option>
                                    <option
                                        value="artistNameAZ"
                                        id="artist-name-a-z"
                                    >
                                        Artist (A–Z)
                                    </option>
                                    <option
                                        value="artistNameZA"
                                        id="artist-name-z-a"
                                    >
                                        Artist (Z–A)
                                    </option>
                                    <option value="albumsOnly" id="albums-only">
                                        Albums
                                    </option>
                                    <option value="epsOnly" id="eps-only">
                                        EPs
                                    </option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                    {productData && (
                        <ul className="product-list grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 pt-4 mb-4 sm:mb-0">
                            {productData.map((item) => {
                                let product = item.node
                                return (
                                    <li
                                        className="product-item"
                                        key={product.id}
                                    >
                                        <Product product={product} />
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                </div>
                <ScrollToTop />
            </Layout>
        </Fragment>
    )
})

export default Catalog

/* eslint-disable */
;(function () {
    var keyPaths = []

    var saveKeyPath = function (path) {
        keyPaths.push({
            sign:
                path[0] === '+' || path[0] === '-'
                    ? parseInt(path.shift() + 1)
                    : 1,
            path: path,
        })
    }

    var valueOf = function (object, path) {
        var ptr = object
        for (var i = 0, l = path.length; i < l; i++) ptr = ptr[path[i]]
        return ptr
    }

    var comparer = function (a, b) {
        for (var i = 0, l = keyPaths.length; i < l; i++) {
            let aVal = valueOf(a, keyPaths[i].path)
            let bVal = valueOf(b, keyPaths[i].path)
            if (aVal > bVal) return keyPaths[i].sign
            if (aVal < bVal) return -keyPaths[i].sign
        }
        return 0
    }

    Array.prototype.sortBy = function () {
        keyPaths = []
        for (var i = 0, l = arguments.length; i < l; i++) {
            switch (typeof arguments[i]) {
                case 'object':
                    saveKeyPath(arguments[i])
                    break
                case 'string':
                    saveKeyPath(arguments[i].match(/[+-]|[^.]+/g))
                    break
            }
        }
        return this.sort(comparer)
    }
})()
