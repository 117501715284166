import React from 'react'

const Loading = () => {
    return (
        <div className="loading px-6 sm:p-0 mt-4 mb-4 text-xl font-semibold text-zinc-400 flex items-center">
            <span className="mr-3">Loading...</span>
            <div className="spinner">
                <div className="bounce1 bg-zinc-200"></div>
                <div className="bounce2 bg-zinc-300"></div>
                <div className="bounce3 bg-zinc-400"></div>
            </div>
        </div>
    )
}

export default Loading
