import React from 'react'
import { useNavigate } from 'react-router-dom'

import Layout from '../Layout/Layout'

import ScrollToTop from '../ScrollToTop/ScrollToTop'

const FormSuccess = () => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1)
        } else {
            navigate('/', { replace: true })
        }
    }

    return (
        <Layout>
            <div className="page">
                <div className={`page-outer flex justify-center m-auto mt-2`}>
                    <div className="page-inner w-full md:w-7/12">
                        <h1 className="page-title text-4xl font-bold">
                            Success!
                        </h1>
                        <div className="page-content">
                            <p>
                                Your message has been submitted. We will get
                                back to you as soon as possible.
                            </p>
                            <p>
                                <span
                                    className="navigate-link"
                                    onClick={handleNavigate}
                                >
                                    &larr; Back
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                )}
            </div>
            <ScrollToTop />
        </Layout>
    )
}

export default FormSuccess
