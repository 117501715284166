import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = React.memo(() => {
    const location = useLocation()

    const pathname = location.pathname

    // console.log('scrollToTop called')

    // just run the effect on pathname and/or search change
    useEffect(() => {
        try {
            // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0)
        }
        return undefined
    }, [pathname])

    // renders nothing, since nothing is needed
    return null
})

export default ScrollToTop
