import React, { useState, useEffect } from 'react'
import Player from './Player'
import { slugify } from './helpers/helpers'
import './assets/styles.css'

const Reaudio = ({ playlist, playing }) => {
    // eslint-disable-next-line
    const [currentPlayer, setCurrentPlayer] = useState()

    console.log('playlist in Reaudio', playlist)

    const product = playlist[0].product

    // initialize current
    let current

    const togglePlay = (id, index) => {
        // get current audio player
        const currentAudio = document.getElementById(id)

        // get all audio players as HTMLCollection
        const players = document.getElementsByTagName('audio')

        // cast HTMLCollection to array so we can do stuff
        const playersArray = [...players]

        // get array of players that are not playing
        const notPlaying = playersArray.filter((item) => {
            if (item !== currentAudio) {
                return item
            }
            return null
        })

        if (!currentAudio.paused) {
            // if we are playing, pause
            currentAudio.pause()
        } else {
            // if we are paused, play
            currentAudio.play()

            // pause all other players
            notPlaying.map((player) => {
                player.pause()
                setCurrentPlayer(!player)
                return null
            })
        }

        // reset current
        current = currentAudio
        setCurrentPlayer(current)
    }

    useEffect(() => {
        if (playing) {
            const currentAudio = document.getElementsByTagName('audio')
            currentAudio[0].play()
        }
    }, [playing, playlist])

    return (
        <div className="reaudio">
            {playlist.map((track, index) => {
                const slug = slugify(
                    track.trackName + '-' + track.trackArtist + '-' + track.id
                )

                return (
                    <Player
                        key={track.id}
                        slug={slug}
                        index={index}
                        source={track.source}
                        togglePlay={togglePlay}
                        trackName={track.trackName}
                        trackArtist={track.trackArtist}
                        trackImage={track.trackImage}
                        loop={track.loop}
                        // isCurrent={true}
                        product={product}
                        isPlaying={playing}
                    />
                )
            })}
        </div>
    )
}

export default Reaudio
