import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import Hero from '../Hero/Hero'
import Callout from '../Callout/Callout'
import Featured from '../Featured/Featured'
import Albums from '../Albums/Albums'
import Spotlight from '../Spotlight/Spotlight'
import Intro from './Intro'
import ScrollToTop from '../ScrollToTop/ScrollToTop'

import { site } from '../../helpers/metadata'

const Home = React.memo(() => {
    return (
        <Fragment>
            <Helmet>
                <title>Vizual Records - Home</title>
                <meta name="description" content={site.description} />
                {/* Facebook Open Graph */}
                <meta
                    property="og:url"
                    content="https://www.vizualrecords.com/"
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={site.title} />
                <meta
                    property="og:image"
                    content="https://vizualassets.s3.us-east-2.amazonaws.com/d-life-fb-event-cover-1920x1005_lineup_KENNY.jpg"
                />
                <meta property="og:image:alt" content="Vizual Records" />
                <meta property="og:description" content={site.description} />
                <meta property="og:site_name" content={site.title} />
                <meta property="og:locale" content="en_US" />
                <meta property="article:author" content={site.title} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta
                    property="twitter:url"
                    content="https://www.vizualrecords.com/"
                />
                <meta property="twitter:title" content={site.title} />
                <meta
                    property="twitter:description"
                    content={site.description}
                />
                <meta
                    property="twitter:image"
                    content="https://vizualassets.s3.us-east-2.amazonaws.com/d-life-fb-event-cover-1920x1005_lineup_KENNY.jpg"
                />
                <a rel="me" href="https://mastodon.social/@joshuaiz">Mastodon</a>
            </Helmet>
            <div className="home">
                <Hero />
                <div className="min-h-[686px]">
                    <Featured />
                </div>

                <Intro />
                <div className="min-h-[686px]">
                    <Albums />
                </div>
                <div className="min-h-[686px]">
                    <Spotlight />
                </div>
            </div>
            <ScrollToTop />
        </Fragment>
    )
})

export default Home
