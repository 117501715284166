import React from 'react'
import parse from 'html-react-parser'

import QuoteIcon from '../Icons/QuoteIcon'

import './Reviews.scss'

const Reviews = ({
    reviews,
    title,
    setShowReviews,
    addToLocalCart,
    product,
    productType,
}) => {
    return (
        <div className="reviews-outer">
            <div className="reviews-inner relative">
                <span
                    className="close-x absolute text-3xl text-zinc-300"
                    onClick={() => setShowReviews(false)}
                >
                    &times;
                </span>
                <h2 className="pb-3 flex flex-col sm:flex-row items-center">
                    <QuoteIcon />
                    <span className="space-space hidden sm:block">&nbsp;</span>
                    <span className="font-bold text-2xl">
                        Reviews for: {title}
                    </span>
                </h2>
                <ul className="reviews reviews-list nostyle">
                    {reviews.map((r, i) => {
                        return (
                            <li
                                key={`review-${i}`}
                                className="review flex flex-col"
                            >
                                <div className="review-content font-medium text-base text-zinc-600 p-4 pb-0 bg-amber-50 h-full">
                                    {parse(r.review)}
                                </div>
                                <div className="review-author mt-auto p-4 bg-stone-100 h-28">
                                    <span className="font-bold text-zinc-700 text-2xl">
                                        {r.reviewer}&nbsp;
                                    </span>
                                    <br />
                                    {r.reviewerAffiliations ? (
                                        <span className="font-normal text-zinc-400 text-xs">
                                            ({r.reviewerAffiliations})
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <button
                    type="button"
                    className="button playlist-button reviews-button bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded mt-3 mb-3"
                    onClick={() => {
                        addToLocalCart({ product })
                    }}
                >
                    Add {productType} To Cart
                </button>
            </div>
        </div>
    )
}

export default Reviews
