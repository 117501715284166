import React from 'react'
import { Link } from 'react-router-dom'

const CartDrawer = ({ isOpen, setIsOpen, cartList, total }) => {
    return (
        <div
            className={`cart-wrap absolute top-4 mt-3 lg:mt-5 w-full sm:w-92 transition duration-500 ease-in-out ${
                isOpen ? 'cart-open' : 'hidden cart-closed'
            }`}
        >
            {cartList && cartList.length ? (
                <div className="cart-list bg-gray-800 pt-3 pr-4 pl-4 pb-4 relative">
                    <span
                        className="cart-close absolute text-gray-700 text-2xl cursor-pointer hover:text-gray-600 pr-1"
                        onClick={() => setIsOpen(!isOpen)}
                        title="Close cart"
                    >
                        &times;
                    </span>
                    <p className="text-gray-400 text-base font-bold pb-2 border-solid border-b border-gray-600 text-left pl-1">
                        Cart
                    </p>
                    <ul className="flex flex-col mt-2 mb-4">
                        {cartList}
                        <li className="cart-total text-gray-100 pt-3 border-solid border-t border-gray-600 text-right text-sm pl-1 pr-6">
                            Total: ${total}
                        </li>
                    </ul>
                    <div className="checkout-button flex mt-5 w-full">
                        <Link to="checkout" className="w-full">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                            >
                                Checkout &rarr;
                            </button>
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="cart-list bg-gray-800 pt-3 pr-4 pl-4 pb-4 relative">
                    <span
                        className="cart-close absolute text-gray-700 text-2xl cursor-pointer hover:text-gray-600"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        &times;
                    </span>
                    <span className="cart-empty text-gray-200">
                        Your cart is empty.
                    </span>
                </div>
            )}
        </div>
    )
}

export default CartDrawer
