import React from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

import { AddToCartContext } from '../../App'

const Product = React.memo(({ product }) => {
    // console.log('product in Product.js', product)
    let src = product.image.sourceUrl
    let alt = product.image.altText
    let fields = product.productFields
    let shortDescription = parse(product.shortDescription)
    let numberOfTracks = fields.numberOfTracks
    let type = product.productCategories.nodes[0].name.toLowerCase()
    let slug = product.slug
    let price = product.price

    let typeSlug = type.toLowerCase()

    let name = product.name

    let catalogNumber = name.substr(0, name.indexOf(' '))
    let title = name.substr(name.indexOf(' ') + 1)

    let arr = title.split('-')

    let artist = arr[0]
    let release = arr[1]

    const addToLocalCart = React.useContext(AddToCartContext)

    const cachedCart = JSON.parse(localStorage.getItem('vizual_cart'))

    let inCart

    if (Array.isArray(cachedCart) && cachedCart.length) {

        inCart = cachedCart.some((item, index) => {
            // console.log('item in localCart', item)
            if (item && item.name) {
                return item.name === name
            }

        })

    }

    return (
        <div className="product border-stone-200">
            <div className="product-image w-full">
                <Link to={`/catalog/${typeSlug}/${slug}`}>
                    <img
                        loading="lazy"
                        className="m-0 w-full"
                        src={src}
                        alt={alt ? alt : `${product.sku} cover art`}
                    />
                </Link>
            </div>
            <div className="product-content p-4 border border-stone-200 bg-white">
                <div className="product-title-wrap h-28">
                    <Link to={`/catalog/${typeSlug}/${slug}`}>
                        <h3 className="text-base text-gray-400 leading-snug font-medium">
                            {catalogNumber}
                        </h3>
                        <h2 className="text-base font-semibold text-zinc-500 leading-tight hover:text-zinc-700">
                            <span className="product-artist">{artist}</span>
                        </h2>
                        <h2 className="text-lg font-semibold text-sky-500 leading-tight hover:text-sky-700">
                            <span className="product-title">{release}</span>
                        </h2>
                    </Link>
                </div>
                <p className="mt-2 mb-2 text-gray-600">
                    {numberOfTracks}-track {type}
                </p>
                <div className="short-description text-sm text-gray-800 leading-snug h-22">
                    {shortDescription}
                </div>
                <div className="text-lg text-gray-700 font-bold mb-2">
                    {price}
                </div>
                <button
                    className={`button bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded mt-3 w-full ${
                        inCart ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={() => {
                        addToLocalCart({ product })
                    }}
                    title={inCart ? name + ' is already in your cart' : ''}
                >
                    Add To Cart
                </button>
            </div>
        </div>
    )
})

export default Product
