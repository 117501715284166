import React, { useContext, Fragment } from 'react'
import TrackInfo from './TrackInfo'
import Play from './Play'

import { AddToCartContext } from '../../App'

import { AudioContext } from '../../App'

const Player = ({
    source,
    slug,
    trackName,
    trackArtist,
    trackImage,
    loop,
    preload,
    showTrackInfo,
    index,
    isCurrent,
    product,
    productFields,
    productImage,
}) => {
    const [audioContext, setAudioContext] = useContext(AudioContext)
    // console.log('product in Player', product)

    // console.log('productFields in Player', productFields)

    // check if source is a single string, if so convert to array
    if (!Array.isArray(source) && String(source) === source) {
        source = source.split()
    }

    const addToLocalCart = React.useContext(AddToCartContext)

    let playlistObject = {
        id: index,
        slug: slug,
        trackName: trackName,
        trackArtist: trackArtist ? trackArtist : productFields.artist,
        trackImage: trackImage ? trackImage : productImage.source,
        source: source,
        product: product,
    }

    console.log('playlistObject', playlistObject)

    const handlePlayClick = (playlistObj) => {
        console.log('play clicked - playlistObj', playlistObj)
        setAudioContext(playlistObj)
    }

    return (
        <Fragment>
            <div className="player mock-player">
                <div className="controls flex-wrap md:flex-no-wrap justify-start md:justify-center">
                    <Play handleClick={() => handlePlayClick(playlistObject)} />

                    <TrackInfo
                        trackName={trackName}
                        trackArtist={trackArtist}
                        trackImage={
                            trackImage ? trackImage : productImage.source
                        }
                    />

                    <div className="player-product flex lg:w-84 md:w-48 justify-end md:justify-around items-center w-full ml-auto pt-3 border-t border-gray-300 md:pt-0 md:border-0 ">
                        <span className="playlist-price">$1.99</span>

                        <button
                            type="button"
                            className="ml-4 button playlist-button playlist-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-sm text-sm md:w-32"
                            onClick={() => {
                                addToLocalCart({ product })
                            }}
                        >
                            Add To Cart
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Player
