import React, { Fragment } from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import Meta from '../Meta/Meta'
import Layout from '../Layout/Layout'
import FacebookIcon from '../Icons/FacebookIcon'
import TwitterIcon from '../Icons/TwitterIcon'
import InstagramIcon from '../Icons/InstagramIcon'
import SoundcloudIcon from '../Icons/SoundcloudIcon'
import WebIcon from '../Icons/WebIcon'
import ScrollToTop from '../ScrollToTop/ScrollToTop'

import { decodeEntities } from '../../helpers/helpers'

import { ARTISTS } from '../../graphql/queries'

const meta = {
    title: 'Artists',
    num: 1,
    slug: 'artists',
}

const Artists = React.memo(() => {
    const { loading, error, data } = useQuery(ARTISTS)

    let artists
    let artistList

    if (data) {
        artists = data.artists.nodes
        let artistsSort = artists
            .slice()
            .sort((a, b) => (a.title > b.title ? 1 : -1))

        // console.log('artistsSort', artistsSort)

        artistList = artistsSort.map((artist, index) => {
            let imageUrl = artist.artists.image.sourceUrl
            let altText = artist.artists.image.altText
            let name = decodeEntities(artist.title)
            let releases = artist.artists.releases
            let links = artist.artists.links

            // return comma separated list
            // solution found here: https://stackoverflow.com/questions/23618744/rendering-comma-separated-list-of-links
            let releaseList = releases.map((release, i) => {
                let ep = release.includes('0')
                return [
                    i > 0 && ', ',
                    <Link
                        key={i + release}
                        to={`/catalog/${
                            ep ? 'ep' : 'album'
                        }/${release.toLowerCase()}`}
                    >
                        {release}
                    </Link>,
                ]
            })

            let linksList = links.map((item, i) => {
                let linkUrl = item.link.url
                let linkTarget = item.link.target
                let linkName = item.linkName
                let icon

                // this is kinda crappy and not DRY but ¯\_(ツ)_/¯
                if (linkName === 'Facebook') {
                    icon = <FacebookIcon />
                } else if (linkName === 'Twitter') {
                    icon = <TwitterIcon />
                } else if (linkName === 'Instagram') {
                    icon = <InstagramIcon />
                } else if (linkName === 'SoundCloud') {
                    icon = <SoundcloudIcon />
                } else if (linkName === 'Website') {
                    icon = <WebIcon />
                }

                return (
                    <li className="artist-link pr-2" key={name + linkName + i}>
                        <a href={linkUrl} target={linkTarget} title={linkName}>
                            <i className={`icon w-6 h-6 text-xs ${linkName}`}>
                                {icon}
                            </i>
                        </a>
                    </li>
                )
            })

            return (
                <li className="artist" key={artist.id}>
                    <div className="text-lg text-gray-800 mb-1 font-bold">
                        {name}
                    </div>
                    <div className="artist-image">
                        <img loading="lazy" src={imageUrl} alt={altText} />
                    </div>
                    <div className="artist-links">
                        {linksList && (
                            <ul className="linksList flex mt-2 mb-2">
                                {linksList}
                            </ul>
                        )}
                    </div>
                    <div className="artist-releases text-sm mt-2 mb-2">
                        <p>Found on: {releaseList && releaseList}</p>
                    </div>
                </li>
            )
        })
    }

    return (
        <Fragment>
            <Meta meta={meta} />
            <Layout title="Artists">
                <div className="artists">
                    <h1 className="page-title text-4xl font-bold mt-2 mb-4">
                        Artists
                    </h1>
                    {loading && (
                        <div className="loading px-6 sm:p-0 mt-4 mb-4 text-xl font-semibold text-gray-500 flex items-center">
                            <span className="mr-3">Loading...</span>
                            <div className="spinner">
                                <div className="bounce1 bg-grey-500"></div>
                                <div className="bounce2 bg-grey-500"></div>
                                <div className="bounce3 bg-grey-500"></div>
                            </div>
                        </div>
                    )}
                    {error && (
                        <div className="data-error">
                            Sorry, the page couldn't be loaded. Try refreshing.
                        </div>
                    )}
                    <ul className="artist-list grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4">
                        {data && artistList}
                    </ul>
                </div>
                <ScrollToTop />
            </Layout>
        </Fragment>
    )
})

export default Artists
