import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useMutation } from '@apollo/client'
import axios from 'axios'

import OrderComplete from './OrderComplete'

import { uuidv4 } from '../../helpers/helpers'

import { CREATE_ORDER } from '../../graphql/mutations'
import { UPDATE_ORDER_PI } from '../../graphql/mutations'

const MEDIA_URL = process.env.REACT_APP_MEDIA_URL
// const FUNCTION_URL = process.env.REACT_APP_FUNCTION_URL
const FUNCTION_URL = process.env.REACT_APP_LIVE_FUNCTION_URL

const PRODUCTS = process.env.REACT_APP_PRODUCTS

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
}

let orderId

const CheckoutForm = React.memo(
    ({ localCart, removeFromLocalCart, setLocalCart }) => {
        const [error, setError] = useState(null)
        const stripe = useStripe()
        const elements = useElements()
        const [inputValue, setInputValue] = useState({
            firstName: '',
            lastName: '',
            email: '',
        })
        const [isRemoving, setIsRemoving] = useState(false)
        const [isProcessing, setIsProcessing] = useState(false)
        const [isCompleted, setIsCompleted] = useState(false)
        const [downloads, setDownloads] = useState()
        const [isChecked, setIsChecked] = useState(false)
        const [cardError, setCardError] = useState({
            error: false,
            errorResponse: '',
        })

        const [createOrder, { data }] = useMutation(CREATE_ORDER)
        const [updateOrder, { updateOrderData }] = useMutation(UPDATE_ORDER_PI)

        // const [computedSubTotal, setComputedSubTotal] = useState(0)

        const [products, setProducts] = useState()

        // let subTotal
        let knownSubTotal

        const lineItems = localCart.map((item) => {
            // console.log('item in checkout', item)
            return {
                productId: item.productId,
                sku: item.sku,
                quantity: 1,
            }
        })

        // console.log('lineItems', lineItems)

        const uuid = uuidv4()

        const input = {
            paymentMethod: 'stripe',
            paymentMethodTitle: 'Stripe',
            isPaid: false,
            clientMutationId: uuid,
            billing: {
                firstName: inputValue.firstName,
                lastName: inputValue.lastName,
                email: inputValue.email,
            },
            lineItems: lineItems,
        }

        // form handler
        const handleInputChange = (e) => {
            const { name, value } = e.target
            setInputValue((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

        // Handle real-time validation errors from the card Element.
        const handleChange = (event) => {
            if (event.error) {
                setError(event.error.message)
            } else {
                setError(null)
            }
        }

        useEffect(() => {
            const fetchData = async () => {
                const result = await axios(PRODUCTS)
                setProducts(result.data)
            }

            fetchData()
        }, [])

        if (products) {
            // console.log('products', products, lineItems)

            const matchedProducts = products.filter((el) => {
                return lineItems.some((f) => {
                    return f.productId === el.id
                })
            })

            // console.log('matchedProducts', matchedProducts)

            let matchedWithSalePrice = matchedProducts.filter((p) => {
                return p.sale_price > 0
            })

            let matchedWithoutSalePrice = matchedProducts.filter((p) => {
                return (
                    p.sale_price === '' ||
                    p.sale_price === 0 ||
                    p.sale_price === undefined ||
                    p.sale_price === null
                )
            })

            // console.log(
            //     'matchedWithSalePrice, matchedWithoutSalePrice',
            //     matchedWithSalePrice,
            //     matchedWithoutSalePrice
            // )

            // if all products have sale price, use sale price only for subtotal
            if (
                matchedWithSalePrice.length &&
                matchedProducts.length === matchedWithSalePrice.length
            ) {
                // get subtotal from known list
                // + operator for casting to Number
                knownSubTotal = matchedWithSalePrice.reduce(
                    (a, b) => +a + +b.sale_price,
                    0
                )

                knownSubTotal = parseFloat(knownSubTotal * 100)

                // console.log('knownSubTotal', knownSubTotal)

                // setComputedSubTotal(knownSubTotal)
            } else {
                // not all products have sale price
                // console.log('not all have sale price')

                let knownSaleSubTotal

                // if we have sale price items
                if (matchedWithSalePrice.length) {
                    knownSaleSubTotal = matchedWithSalePrice.reduce(
                        (a, b) => +a + +b.sale_price,
                        0
                    )
                }

                // we know we have regular price items
                // get regular price items subtotal
                let knownRegularSubTotal = matchedWithoutSalePrice.reduce(
                    (a, b) => +a + +b.regular_price,
                    0
                )

                // console.log(
                //     'knownSaleSubtotal, knownRegularSubtotal',
                //     knownSaleSubTotal,
                //     knownRegularSubTotal
                // )

                knownSubTotal = knownSaleSubTotal + knownRegularSubTotal

                knownSubTotal = parseFloat(knownSubTotal * 100)

                // console.log('knownSubTotal in mixed', knownSubTotal)

                // setComputedSubTotal(knownSubTotal)
            }
        }

        // Process the order on submit
        const handleSubmit = async (event) => {
            event.preventDefault()

            setIsProcessing(true)

            const handleCreateOrder = async () => {
                try {
                    const { data: orderData } = await createOrder({
                        variables: { input: input },
                    })
                    return { data: orderData }
                } catch (err) {
                    console.log('handleCreateOrder', err)
                }
            }

            const getCustomer = async () => {
                try {
                    const { data: customer } = await axios.post(
                        FUNCTION_URL + 'customer'
                    )
                    // console.log('customer in getCustomer', { data: customer })
                    return { data: customer }
                } catch (err) {
                    console.log('getCustomer', err)
                }
            }

            const getClientSecret = async () => {
                try {
                    const { data: clientSecret } = await axios.post(
                        FUNCTION_URL + 'paymentintent',
                        {
                            amount: knownSubTotal && knownSubTotal,
                        }
                    )
                    return { data: clientSecret }
                } catch (err) {
                    console.log(err)
                }
            }

            // make sure we have everything before confirming payment
            const [orderData, customer, clientSecret] = await Promise.all([
                handleCreateOrder(),
                getCustomer(),
                getClientSecret(),
            ])

            if (customer && clientSecret && orderData) {
                const paymentIntentId = clientSecret.data.clientSecret
                const stripeCustomerId = customer.data.customer.id
                orderId = orderData.data.createOrder.order.orderId

                const result = await stripe.confirmCardPayment(
                    paymentIntentId,
                    {
                        payment_method: {
                            card: elements.getElement(CardElement),
                            billing_details: {
                                name:
                                    inputValue.firstName +
                                    ' ' +
                                    inputValue.lastName,
                                email: inputValue.email,
                            },
                        },
                    }
                )

                if (result.error) {
                    // Show error to your customer (e.g., insufficient funds)
                    console.log(result.error.message)
                    setCardError({
                        error: true,
                        errorResponse: result.error.message,
                    })
                    setIsProcessing(false)
                } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'succeeded') {
                        // Show a success message to your customer
                        // There's a risk of the customer closing the window before callback
                        // execution. Set up a webhook or plugin to listen for the
                        // payment_intent.succeeded event that handles any business critical
                        // post-payment actions.
                        console.log('result', result)

                        setCardError({
                            error: false,
                            errorResponse: '',
                        })

                        const updateOrderInput = {
                            clientMutationId: 'Vizual',
                            orderId: orderId,
                            isPaid: true,
                            metaData: [
                                {
                                    key: '_stripe_customer_id',
                                    value: stripeCustomerId,
                                },
                                {
                                    key: '_stripe_intent_id',
                                    value: paymentIntentId,
                                },
                            ],
                        }

                        const handleUpdateOrder = async () => {
                            try {
                                const { data: updateOrderData } =
                                    await updateOrder({
                                        variables: { input: updateOrderInput },
                                    })
                                console.log('handleUpdateOrder', {
                                    data: updateOrderData,
                                })
                                console.log('updateOrderData', updateOrderData)
                                return { data: updateOrderData }
                            } catch (err) {
                                console.log('handleUpdateOrder', err)
                            }
                        }

                        const { updateOrderData } = await handleUpdateOrder()

                        handleUpdateOrder().then((response) => {
                            // console.log('handleUpdateOrder response', response)
                            if (
                                response.data.updateOrder.order.status ===
                                'COMPLETED'
                            ) {
                                console.log('order status COMPLETED')
                                setIsProcessing(false)
                                setIsCompleted(true)
                                setLocalCart([])
                                localStorage.removeItem('vizual_cart')
                                setDownloads(
                                    response.data.updateOrder.order
                                        .downloadableItems.edges
                                )
                            }
                        })

                        // If customer opts in, send email via Emailjs + add to Contacts
                        if (isChecked) {
                            let template_params = {
                                email: inputValue.email,
                                firstName: inputValue.firstName,
                                lastName: inputValue.lastName,
                            }

                            let service_id = 'default_service'
                            let template_id = 'vizual_opt_in'
                            window.emailjs
                                .send(service_id, template_id, template_params)
                                .then((response) => {
                                    // console.log('emailjs response', response)
                                })
                        }
                    }
                }
            }
        }

        // function to handle animating removing item
        const handleRemoveFromCheckout = ({ item }) => {
            setIsRemoving(true)
            var element = document.getElementById(`checkout-${item.id}`)
            // console.log('element', element)
            element.classList.add('fadeOut')
            setTimeout(() => {
                setIsRemoving(false)
                if (!isRemoving) {
                    removeFromLocalCart({ item })
                    element.classList.remove('fadeOut')
                }
            }, 750)
        }

        const handleOptIn = (event) => {
            setIsChecked(!isChecked)
        }

        // console.log('localCart in Cart.js', localCart)
        // const numProducts = localCart.length

        // get cart total using map + reduce
        const add = (a, b) => parseFloat(a) + parseFloat(b)

        let total = localCart
            .map((item) => Number(item.price.replace(/[^0-9.-]+/g, '')))
            .reduce(add, 0)

        total = parseFloat(total).toFixed(2)

        // console.log('localCart', localCart)
        const cartList = localCart.map((item, index) => {
            let slug = item.sku.split('-')[0]
            let slugPath = item.slug
            let type
            if (item.productCategories) {
                type = item.productCategories.nodes[0].name.toLowerCase()
            }
            let thumbnailSrc = `${MEDIA_URL}${slug}-100x100.png`

            // console.log('cart item', item)

            return (
                <li
                    className={`cart-product text-left flex items-start text-gray-800 text-xs mt-1 mb-4 px-1`}
                    key={`cart-product-${item.id}`}
                    id={`checkout-${item.id}`}
                >
                    <div className="product-image w-6 mr-2">
                        <Link to={`/catalog/${type}/${slugPath}`}>
                            <img src={thumbnailSrc} alt={item.name} />
                        </Link>
                    </div>
                    <div className="product-info mr-2 flex w-full text-base items-start text-xs leading-tight">
                        <span className="product-quantity text-gray-500 mr-1">
                            1
                        </span>
                        &nbsp;
                        <span className="cart-name">
                            {item.name && item.name
                                ? item.name
                                : item.title && item.title}
                        </span>
                        <span className="cart-price ml-auto px-2">
                            $
                            {parseFloat(
                                Number(item.price.replace(/[^0-9.-]+/g, ''))
                            ).toFixed(2)}
                        </span>
                    </div>

                    <span
                        className="close-x opacity-25 text-red-900 text-xl cursor-pointer ml-auto hover:text-red-600 hover:opacity-100 w-3 pb-1"
                        onClick={() => {
                            handleRemoveFromCheckout({ item })
                        }}
                        title="Remove"
                    >
                        &times;
                    </span>
                </li>
            )
        })

        return (
            <div className="checkout-form max-w-xl m-auto p-6 border border-gray-300 rounded">
                <h1 className="page-title text-4xl font-bold mt-0 mb-4 leading-tight tracking-tight">
                    {!isCompleted ? 'Checkout' : 'Your order is complete.'}
                </h1>
                {!isCompleted && localCart.length ? (
                    <div className="checkout-wrap">
                        <div className="cartlist p-4 bg-gray-100 rounded mb-6">
                            <ul className="flex flex-col mt-2 mb-0">
                                {cartList && cartList}
                                <li className="cart-total text-gray-900 pt-3 border-solid border-t border-gray-400 text-right text-xl pl-1 pr-8">
                                    Total:{' '}
                                    <span className="font-bold">${total}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="checkout-note px-1">
                            <p className="text-gray-600 my-6">
                                👁‍🗨&nbsp;After completing your purchase any
                                downloads will be available on this page and
                                also sent to you via email.
                            </p>
                        </div>
                        <form
                            onSubmit={handleSubmit}
                            className="max-w-xl m-auto px-1"
                        >
                            <div className="checkout-name-wrap form-row flex w-full justify-between">
                                <label className="w-1/2 mr-4 mb-4">
                                    <span className="form-label">
                                        First Name
                                    </span>
                                    <input
                                        className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-sm py-2 px-4 block appearance-none leading-normal w-full"
                                        autoFocus
                                        name="firstName"
                                        type="name"
                                        value={inputValue.firstName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                                <label className="w-1/2">
                                    <span className="form-label">
                                        Last Name
                                    </span>
                                    <input
                                        className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-sm py-2 px-4 block  appearance-none leading-normal w-full"
                                        name="lastName"
                                        type="name"
                                        value={inputValue.lastName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="checkout-email-wrap form-row mb-4">
                                <label>
                                    <span className="form-label">Email</span>
                                    <input
                                        className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-sm py-3 px-4 block w-full appearance-none leading-normal"
                                        name="email"
                                        type="email"
                                        value={inputValue.email}
                                        onChange={handleInputChange}
                                        placeholder="you@example.com"
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-row mb-4">
                                <label htmlFor="card-element">
                                    Credit or debit card
                                </label>
                                <CardElement
                                    id="card-element"
                                    options={CARD_ELEMENT_OPTIONS}
                                    onChange={handleChange}
                                    className={`bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-sm py-4 px-4 block appearance-none leading-normal w-full ${
                                        cardError.error === true
                                            ? 'border-red-500'
                                            : null
                                    }`}
                                />
                                <div
                                    className="card-errors text-red-600"
                                    role="alert"
                                >
                                    {error}
                                </div>
                            </div>
                            <div className="checkout-optin-wrap form-row mb-4">
                                <label className="flex items-center">
                                    <input
                                        className="mr-2 leading-tight"
                                        name="optin"
                                        type="checkbox"
                                        onChange={handleOptIn}
                                    />
                                    <span className="text-sm">
                                        Get exclusive special offers plus
                                        updates on Vizual releases and events.
                                    </span>
                                </label>
                            </div>
                            <button
                                type="submit"
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded mt-3 w-full text-lg ${
                                    isProcessing
                                        ? 'opacity-50 cursor-not-allowed'
                                        : ''
                                }`}
                            >
                                Place Order
                            </button>
                        </form>
                        {cardError.error === true && (
                            <div className="payment-error mt-3">
                                <p className="text-red-600 font-semibold mb-0">
                                    {cardError.errorResponse}
                                </p>
                                <p className="text-gray-700 m-0">
                                    Please check your card details and try
                                    again.
                                </p>
                            </div>
                        )}
                    </div>
                ) : (
                    <Fragment>
                        {!isCompleted && (
                            <div className="cart-empty">
                                <p>Your cart is empty.</p>
                                <p>
                                    <Link to="/catalog">
                                        &larr; Continue Shopping
                                    </Link>
                                </p>
                            </div>
                        )}
                    </Fragment>
                )}

                {isCompleted ? (
                    <OrderComplete
                        isProcessing={isProcessing}
                        downloads={downloads}
                    />
                ) : (
                    isProcessing &&
                    !cardError.error && (
                        <div className="processing flex items-center mt-4">
                            <p className="text-lg font-bold text-blue-800 mr-2 mb-0">
                                We're loading up your downloads. Hold tight.
                            </p>
                            <div className="spinner text-blue-700">
                                <div className="bounce1 bg-blue-700"></div>
                                <div className="bounce2 bg-blue-700"></div>
                                <div className="bounce3 bg-blue-700"></div>
                            </div>
                        </div>
                    )
                )}
            </div>
        )
    }
)

export default CheckoutForm
