import React, { useContext, useState, useEffect } from 'react'

import Reaudio from '../Reaudio/Reaudio'

import { AudioContext } from '../../App'

const GlobalPlayer = () => {
    const [audioContext, setAudioContext] = useContext(AudioContext)

    console.log('audioContext in global player', audioContext)

    let playlist

    if (audioContext) {
        let source = audioContext.source

        if (!Array.isArray(source) && String(source) === source) {
            source = source.split()
        }

        playlist = [
            {
                id: audioContext.id,
                slug: audioContext.slug,
                trackName: audioContext.trackName,
                trackArtist: audioContext.trackArtist,
                trackImage: audioContext.trackImage,
                source: source,
                product: audioContext.product,
            },
        ]


    }

    const handleClosePlayer = () => {
        setAudioContext(null)
    }

    return (
        <div
            className={`global-player-outer ${
                audioContext ? 'block' : 'hidden'
            }`}
        >
            <div className="global-player-inner relative w-full">
                <span
                    className="close-x absolute -top-1 -right-1 text-lg text-white hover:bg-gray-900 cursor-pointer w-[24px] h-[24px] flex items-center justify-center rounded-full bg-gray-700"
                    onClick={handleClosePlayer}
                >
                    &times;
                </span>
                {playlist && <Reaudio playlist={playlist} playing={true} />}
            </div>
        </div>
    )
}

export default GlobalPlayer
