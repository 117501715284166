import React from 'react'

import Layout from '../Layout/Layout'

const Contact = () => {
    return (
        <Layout>
            <div className="contact">
                <div className="page-outer max-w-3xl mx-auto">
                    <h1 className="page-title text-4xl font-bold mt-2 mb-4">
                        Contact Us
                    </h1>
                    <div className="page-content">
                        <p>
                            Enter your information and message below. Someone
                            from Vizual Records will get back to you as soon as
                            possible.
                        </p>

                        <p>
                            If you are having trouble with the site or
                            downloads, please include any error messages.
                        </p>

                        <p>This form is temporarily disabled. Please email us at info at vizualrecords dot com.</p>
                        <div className="form-outer">
                            <form
                                name="contact"
                                method="post"
                                action="/success"
                                netlify-honeypot="bot-field"
                                data-netlify="true"
                            >
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="contact"
                                />
                                <input type="hidden" name="bot-field" />
                                <div className="name-input">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="name"
                                    >
                                        Full name:
                                    </label>
                                    <div className="form-input-container">
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            autoFocus
                                            name="name"
                                            type="text"
                                            placeholder="Enter your full name"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="email-input">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="email"
                                    >
                                        Email:
                                    </label>
                                    <div className="form-input-container">
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            name="email"
                                            type="email"
                                            placeholder="you@example.com"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="message-input">
                                    <label
                                        className="block text-gray-700 text-sm font-bold mb-2"
                                        htmlFor="message"
                                    >
                                        Your Message:
                                    </label>
                                    <div className="form-input-container">
                                        <textarea
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="feedback-entry"
                                            name="message"
                                            placeholder="Enter your message here. Maximum 1000 characters."
                                            maxLength="1000"
                                            required
                                        />
                                    </div>
                                </div>
                                {/* <input
                                    type="submit"
                                    value="Submit"
                                    className="button button-submit"
                                    disabled={true}
                                /> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Contact
