import { gql } from '@apollo/client'

export const VIZUAL_CATALOG = gql`
    query VizualCatalog {
        products(where: { categoryIdIn: [16, 17] }, first: 50) {
            edges {
                node {
                    id
                    image {
                        id
                        altText
                        sourceUrl
                    }
                    name
                    featured
                    productFields {
                        artist
                        cat
                        fieldGroupName
                        releaseDate
                        releaseTitle
                        numberOfTracks
                        tracks {
                            trackArtist
                            trackAudio
                            trackProductId
                            trackTitle
                        }
                    }
                    slug
                    sku
                    ... on SimpleProduct {
                        id
                        price
                    }
                    description
                    shortDescription
                    productCategories {
                        nodes {
                            id
                            name
                        }
                    }
                    productTags {
                        nodes {
                            name
                        }
                    }
                }
            }
        }
    }
`

export const HERO_IMAGES = gql`
    query HeroImages {
        products(where: { categoryIdIn: [16, 17], featured: true }, first: 5) {
            nodes {
                id
                slug
                productFields {
                    fieldGroupName
                    hero {
                        id
                        altText
                        sourceUrl
                    }
                }
                productCategories {
                    nodes {
                        id
                        slug
                    }
                }
            }
        }
    }
`

export const NEW_RELEASES = gql`
    query Products {
        products(where: { categoryIdIn: [16, 17], featured: true }, first: 5) {
            edges {
                node {
                    id
                    name
                    slug
                    sku
                    image {
                        id
                        sourceUrl
                        altText
                    }
                    productFields {
                        fieldGroupName
                        artist
                        cat
                        numberOfTracks
                    }
                    shortDescription
                    productCategories {
                        nodes {
                            id
                            name
                        }
                    }
                    ... on SimpleProduct {
                        id
                        price
                    }
                }
            }
        }
    }
`

export const ALBUMS = gql`
    query Albums {
        products(
            where: { categoryIdIn: 16, orderby: { field: DATE, order: ASC } }
            first: 5
        ) {
            edges {
                node {
                    id

                    image {
                        id
                        altText
                        sourceUrl
                    }
                    name
                    productFields {
                        fieldGroupName
                        artist
                        cat
                        numberOfTracks
                    }
                    slug
                    sku
                    ... on SimpleProduct {
                        id
                        price
                    }
                    shortDescription
                    productCategories {
                        nodes {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const SPOTLIGHT = gql`
    query Spotlight {
        products(where: { tagIn: "spotlight" }, first: 5) {
            edges {
                node {
                    id

                    image {
                        id
                        altText
                        sourceUrl
                    }
                    name
                    productFields {
                        artist
                        cat
                        fieldGroupName
                        numberOfTracks
                    }
                    slug
                    sku
                    ... on SimpleProduct {
                        id
                        price
                    }
                    shortDescription
                    productCategories {
                        nodes {
                            name
                        }
                    }
                }
            }
        }
    }
`

export const SINGLE_PRODUCT = gql`
    query Single($slug: [String]) {
        products(first: 1, where: { slugIn: $slug }) {
            nodes {
                description
                shortDescription
                id
                image {
                    id
                    altText
                    sourceUrl
                }
                name
                productFields {
                    artist
                    cat
                    fieldGroupName
                    numberOfTracks
                    tracks {
                        trackArtist
                        trackAudio
                        trackProductId
                        trackTitle
                    }
                    reviews {
                        review
                        reviewer
                        reviewerAffiliations
                    }
                }
                sku
                ... on SimpleProduct {
                    id
                    price
                }
                productCategories {
                    nodes {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const UPSELLS = gql`
    query Upsells($slug: [String]) {
        products(where: { slugIn: $slug }) {
            nodes {
                id
                upsell {
                    nodes {
                        description
                        shortDescription
                        name
                        sku
                        slug
                        productFields {
                            artist
                            cat
                            fieldGroupName
                            numberOfTracks
                        }
                        ... on SimpleProduct {
                            id
                            price
                        }
                        id
                        image {
                            id
                            altText
                            sourceUrl
                        }
                        productCategories {
                            nodes {
                                id
                                name
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
`

export const TRACKS = gql`
    query Tracks($slug: String) {
        products(first: 20, where: { sku: $slug, categoryIdNotIn: [16, 17] }) {
            nodes {
                name
                id
                sku
                ... on SimpleProduct {
                    id
                    price
                }
            }
        }
    }
`

export const PAGE = gql`
    query Page($id: ID!) {
        page(id: $id, idType: URI) {
            title(format: RENDERED)
            content(format: RENDERED)
        }
    }
`

export const ARTISTS = gql`
    query Artists {
        artists(first: 50) {
            nodes {
                id
                title
                artists {
                    bio
                    image {
                        id
                        sourceUrl
                    }
                    releases
                    links {
                        link {
                            target
                            title
                            url
                        }
                        linkName
                    }
                }
            }
        }
    }
`

export const DOWNLOADS = gql`
    query Downloads($orderId: Int) {
        order(orderId: $orderId) {
            downloadableItems {
                edges {
                    node {
                        downloadId
                        download {
                            downloadId
                            file
                            name
                        }
                    }
                }
            }
        }
    }
`

export const CLOTHING = gql`
    query Clothing {
        allClothing(first: 100) {
            nodes {
                id
                title(format: RENDERED)
                slug
                clothingFields {
                    design {
                        altText
                        id
                        mediaItemUrl
                    }
                    defaultType {
                        id
                        name
                        slug
                        description
                        clothingTypeFields {
                            blankImage {
                                altText
                                id
                                slug
                                sourceUrl
                                title
                            }
                            colorset
                            price
                        }
                    }
                    defaultColorName
                    defaultColorHex
                    defaultColorset
                    colorset1
                    colorset2
                    colorset3
                    colorset4
                    colorset5
                    colorset6
                    colorset7
                    colorset8
                    colorset9
                    colorset10
                    colorset11
                    colorset12
                    colorset13
                    colorset14
                    colorset15
                    colorset16
                    colorset17
                    clothingType {
                        id
                        name
                        slug
                        description
                        clothingTypeFields {
                            blankImage {
                                altText
                                id
                                slug
                                sourceUrl
                                title
                            }
                            colorset
                            price
                        }
                        ancestors {
                            nodes {
                                id
                                name
                                slug
                                description
                                ancestors {
                                    nodes {
                                        id
                                        name
                                        slug
                                        description
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const OUTLETS = gql`
    query Outlets {
        vizualOutlets(first: 30) {
            nodes {
                id
                slug
                title(format: RENDERED)
                outletCategories {
                    nodes {
                        id
                        name
                        slug
                    }
                }
                outletFields {
                    fieldGroupName
                    text
                    image {
                        altText
                        id
                        mediaItemUrl
                    }
                    link {
                        target
                        title
                        url
                    }
                }
            }
        }
    }
`
