import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'
import { firstDateIsPastDayComparedToSecond } from '../src/helpers/helpers'

import App from './App'

import './assets/main.css'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

let cacheReset = sessionStorage.getItem('cacheReset') || false

// const URL = process.env.REACT_APP_LOCAL_URL
const LIVE_URL = process.env.REACT_APP_LIVE_URL

const cache = new InMemoryCache({
    typePolicies: {
        RootQueryToProductConnection: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
        },
        Product_Productfields: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            artist: {
                keyFields: ['cat'],
            },
            tracks: {
                keyFields: ['trackAudio'],
            },
        },
    },
})

const today = new Date()
const past = new Date(2022, 9, 22)

const inPast = firstDateIsPastDayComparedToSecond(past, today) //true

// console.log('inPast', inPast)

persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
})

const client = new ApolloClient({
    cache,
    uri: LIVE_URL,
})

if (inPast && !cacheReset) {
    console.log('cache reset')
    client.resetStore()
    sessionStorage.setItem('cacheReset', 'true')
}

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
