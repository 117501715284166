import React, { useState, useEffect, Fragment } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

import Search from '../Search/Search'
import Logo from '../Logo/Logo'
import Cart from '../Cart/Cart'
import SearchIcon from '../Icons/SearchIcon'

const Header = React.memo(({ localCart }) => {
    let location = useLocation()
    const [isSearching, setIsSearching] = useState(false)

    const handleIsSearching = () => {
        setIsSearching(!isSearching)
    }

    const handleResultClick = () => {
        setIsSearching(false)
    }

    useEffect(() => {
        setIsSearching(false)
        return () => {
            setIsSearching(false)
        }
    }, [location.pathname])

    // close search bar when escape is pressed
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setIsSearching(false)
            }
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [])

    return (
        <Fragment>
            <Search
                isSearching={isSearching}
                handleIsSearching={handleIsSearching}
                handleResultClick={handleResultClick}
            />
            <header
                className={`header bg-gray-900 flex flex-col lg:h-16 w-full z-20
                     ${isSearching ? 'top-16' : 'sticky top-0'}`}
            >
                <div className="inner-header container flex items-center w-full m-auto px-4 lg:px-0 overflow-x-hidden">
                    <Logo />
                    <div className="site-title pl-3 pr-4 hidden sm:block">
                        <h1 className="text-gray-100 font-bold font-sans">
                            <Link to="/">Vizual Records</Link>
                        </h1>
                    </div>
                    <MainMenu />
                    <div
                        className="search-icon-wrap ml-auto mr-3"
                        onClick={handleIsSearching}
                    >
                        <SearchIcon
                            width={'4'}
                            color={'text-gray-100'}
                            cursor={'pointer'}
                        />
                    </div>
                    <Cart localCart={localCart} />
                </div>
            </header>
        </Fragment>
    )
})

const MainMenu = React.memo(() => {
    return (
        <nav className="nav-menu main-menu h-full">
            <ul className="nostyle text-gray-100 flex items-center h-full">
                <li className="px-2 sm:px-4">
                    <NavLink to="/catalog" className="hover:text-sky-500">
                        Catalog
                    </NavLink>
                </li>
                <li className="px-2 sm:px-4">
                    <NavLink to="/artists" className="hover:text-sky-500">
                        Artists
                    </NavLink>
                </li>
                <li className="px-2 sm:px-4">
                    <NavLink to="/store" className="hover:text-sky-500">
                        Store
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
})

export default Header
