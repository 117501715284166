import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import useKeyPress from '../../hooks/useKeyPress'

const MEDIA_URL = 'https://vizualassets.s3.us-east-2.amazonaws.com/'

const Results = ({ hits, currentRefinement, handleResultClick }) => {
    const downPress = useKeyPress('ArrowDown')
    const upPress = useKeyPress('ArrowUp')
    const enterPress = useKeyPress('Enter')
    const [cursor, setCursor] = useState(0)

    const linkRef = useRef(null)

    useEffect(() => {
        if (hits.length && downPress) {
            setCursor((prevState) =>
                prevState < hits.length - 1 ? prevState + 1 : prevState
            )
        }
    }, [downPress, hits.length])

    useEffect(() => {
        if (hits.length && upPress) {
            setCursor((prevState) =>
                prevState > 0 ? prevState - 1 : prevState
            )
        }
    }, [upPress, hits.length])

    useEffect(() => {
        if (hits.length && enterPress && cursor > -1) {
            document.querySelector('.active-item a').click()
        }
    }, [cursor, enterPress, hits.length])

    let title

    let results = hits.map((hit, i) => {
        // console.log('hit', hit)
        let cat = hit.cat
        let artist = false

        const tag = hit.tags[0]

        if (cat && cat.length > 0) {
            cat = hit.cat[0].toLowerCase()
        } else {
            artist = true
        }

        const link = `/catalog/${tag}/${cat}`
        const thumbnailSrc = `${MEDIA_URL}${hit.cat[0]}-100x100.png`
        title = hit.title

        // we are only returning top-level products from search
        if (tag === 'ep' || tag === 'album') {
            return (
                <li
                    className={`p-2 text-sm flex items-center hover:bg-gray-200 ${
                        i === cursor
                            ? 'bg-yellow-500 active-item'
                            : 'bg-gray-100'
                    }`}
                    key={hit.post_id}
                    active={i === cursor ? 'true' : 'false'}
                >
                    <div className="result-image mr-2">
                        <img
                            className="w-8"
                            src={thumbnailSrc}
                            alt={`${title} cover art`}
                        />
                    </div>
                    <Link ref={linkRef} to={link} onClick={handleResultClick}>
                        <Highlighted
                            text={title}
                            highlight={currentRefinement}
                        />{' '}
                        <span className="text-gray-500">({tag})</span>
                    </Link>
                </li>
            )
        } else if (artist) {
            return (
                <li
                    className={`p-2 text-sm flex items-center hover:bg-gray-200 ${
                        i === cursor
                            ? 'bg-yellow-500 active-item'
                            : 'bg-gray-100'
                    }`}
                    key={hit.post_id}
                    active={i === cursor ? 'true' : 'false'}
                >
                    <div className="result-image mr-2">
                        <img
                            className="w-8"
                            src={hit.image ? hit.image : undefined}
                            alt={`${title} headshot`}
                        />
                    </div>
                    <Link
                        ref={linkRef}
                        to="/artists"
                        onClick={handleResultClick}
                    >
                        <Highlighted
                            text={title}
                            highlight={currentRefinement}
                        />{' '}
                        <span className="text-gray-500">(artist)</span>
                    </Link>
                </li>
            )
        } else {
            let parent
            if (cat.includes('0')) {
                parent = 'ep'
            } else {
                parent = 'album'
            }
            return (
                <li
                    className={`p-2 text-sm flex items-center hover:bg-gray-200 ${
                        i === cursor
                            ? 'bg-yellow-500 active-item'
                            : 'bg-gray-100'
                    }`}
                    key={hit.post_id}
                    active={i === cursor ? 'true' : 'false'}
                >
                    <div className="result-image mr-2">
                        <img
                            className="w-8"
                            src={hit.image ? hit.image : undefined}
                            alt={`${title}`}
                        />
                    </div>
                    <Link ref={linkRef} to={`/catalog/${parent}/${cat}`}>
                        <Highlighted
                            text={title}
                            highlight={currentRefinement}
                        />{' '}
                        <span className="text-gray-500">
                            (track on {cat.toUpperCase()})
                        </span>
                    </Link>
                </li>
            )
        }
    })

    return (
        <ul className="search-results absolute w-full bg-gray-200 z-auto shadow-lg">
            {currentRefinement && results}
        </ul>
    )
}

const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
        return <span>{text}</span>
    }
    const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
        <span>
            {parts
                .filter((part) => part)
                .map((part, i) =>
                    regex.test(part) ? (
                        <mark key={i}>{part}</mark>
                    ) : (
                        <span key={i}>{part}</span>
                    )
                )}
        </span>
    )
}

const escapeRegExp = (str = '') => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')

export default Results
