import { gql } from '@apollo/client'

export const CREATE_ORDER = gql`
    mutation createOrder($input: CreateOrderInput!) {
        createOrder(input: $input) {
            clientMutationId
            order {
                id
                orderId
            }
        }
    }
`

export const CHECKOUT = gql`
    mutation Checkout($input: CheckoutInput!) {
        checkout(input: $input) {
            result
            order {
                id
            }
        }
    }
`

export const UPDATE_ORDER_PI = gql`
    mutation updateOrder($input: UpdateOrderInput!) {
        updateOrder(input: $input) {
            order {
                orderId
                status
                downloadableItems {
                    edges {
                        node {
                            url
                            name
                            downloadId
                            download {
                                name
                                file
                                downloadId
                            }
                            product {
                                id
                                image {
                                    id
                                    altText
                                    mediaDetails {
                                        sizes {
                                            sourceUrl
                                        }
                                    }
                                }
                                productFields {
                                    cat
                                    fieldGroupName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const AUTH = gql`
    mutation LoginUser {
        login(
            input: {
                clientMutationId: "uniqueId"
                username: "studiobio"
                password: "studio.bio"
            }
        ) {
            authToken
            user {
                id
                name
            }
        }
    }
`
