import React, { useState, useEffect, useRef, Fragment } from 'react'
import TrackInfo from './TrackInfo'
import Play from './Play'
import Pause from './Pause'
import Bar from './Bar'

import { AddToCartContext } from '../../App'

const Player = ({
    source,
    slug,
    trackName,
    trackArtist,
    trackImage,
    loop,
    preload,
    showTrackInfo,
    togglePlay,
    index,
    isCurrent,
    product,
    productFields,
    productImage,
    isPlaying,
}) => {
    const audioRef = useRef()

    const [duration, setDuration] = useState()
    const [curTime, setCurTime] = useState()
    const [playing, setPlaying] = useState(isPlaying)
    const [clickedTime, setClickedTime] = useState()

    // console.log('product in Player', product)

    // console.log('productFields in Player', productFields)

    // check if source is a single string, if so convert to array
    if (!Array.isArray(source) && String(source) === source) {
        source = source.split()
    }

    const addToLocalCart = React.useContext(AddToCartContext)

    useEffect(() => {
        const audio = audioRef.current

        // state setters
        const setAudioData = () => {
            setDuration(audio.duration)
            setCurTime(audio.currentTime)
        }

        const setAudioTime = () => setCurTime(audio.currentTime)

        // DOM listeners: update React state on DOM events
        audio.addEventListener('loadeddata', setAudioData)
        audio.addEventListener('timeupdate', setAudioTime)

        if (clickedTime && clickedTime !== curTime) {
            audio.currentTime = clickedTime
            setClickedTime(null)
        }

        // effect cleanup
        return () => {
            audio.removeEventListener('loadeddata', setAudioData)
            audio.removeEventListener('timeupdate', setAudioTime)
        }
        // eslint-disable-next-line
    }, [clickedTime, curTime])

    return (
        <Fragment>
            <div className={playing ? 'player playing-player' : 'player'}>
                <audio
                    id={slug}
                    className={
                        playing ? 'audio-playing audio-player' : 'audio-player'
                    }
                    ref={audioRef}
                    loop={loop}
                    preload={preload}
                    data-track-name={trackName ? trackName : ''}
                    data-track-artist={trackArtist ? trackArtist : ''}
                    data-track-image={trackImage ? trackImage : ''}
                    data-playing={playing ? 'true' : 'false'}
                    data-src={source[0]}
                >
                    {source &&
                        source.map((src, i) => {
                            return <source key={i} src={src} />
                        })}
                    Your browser does not support the <code>audio</code>{' '}
                    element.
                </audio>

                <div className="controls flex-wrap md:flex-nowrap justify-start md:justify-center">
                    {playing ? (
                        <Pause
                            handleClick={() => {
                                setPlaying(false)
                                togglePlay(slug, index)
                            }}
                        />
                    ) : (
                        <Play
                            handleClick={() => {
                                setPlaying(true)

                                togglePlay(slug, index)
                            }}
                        />
                    )}
                    {showTrackInfo && (
                        <TrackInfo
                            trackName={trackName}
                            trackArtist={trackArtist}
                            trackImage={trackImage ? trackImage : null}
                        />
                    )}
                    <Bar
                        curTime={curTime}
                        duration={duration}
                        onTimeUpdate={(time) => setClickedTime(time)}
                    />
                    <div className="player-product flex lg:w-84 md:w-48 justify-end md:justify-around items-center w-full ml-auto pt-3 border-t border-gray-300 md:pt-0 md:border-0 ">
                        <span className="playlist-price">$1.99</span>

                        <button
                            type="button"
                            className="ml-4 button playlist-button playlist-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-sm text-sm md:w-32"
                            onClick={() => {
                                addToLocalCart({ product })
                            }}
                        >
                            Add To Cart
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

Player.defaultProps = {
    source: 'https://studio.bio/reaudio/iiwii.mp3',
    showTrackInfo: true,
    trackName: 'Unknown',
    trackArtist: 'Unknown Artist',
    loop: false,
    preload: 'auto',
}

export default Player
