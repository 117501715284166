export const site = {
    title: 'Vizual Records',
    description:
        'Listen to and download music from the Vizual Records Catalog featuring Joshua Iz, Iz & Diz, Derrick Carter, Chez Damier, Aiby & The Noise, Rubba J and more. Now with full-quality AIFF audio.',
    pageDescriptions: [
        {
            page: 'catalog',
            desc:
                'The Vizual Records Catalog: listen, buy, and download music from Joshua Iz, Iz & Diz, Derrick Carter, Chez Damier, Aiby & The Noise, Rubba J and more. Full-length albums, EPs, and individual tracks, all in perfect AIFF audio.',
        },
        {
            page: 'artists',
            desc:
                'Recording Artists on Vizual Records: view releases and links to websites, social media profiles, and more. Featuring Joshua Iz, Iz & Diz, Derrick Carter, Chez Damier, Aiby & The Noise, Pépé Bradock, JT Donaldson, Atnarko, and more.',
        },
        {
            page: 'outlets',
            desc:
                'Where to listen, stream, download, and buy Vizual Records music. Links to Spotify, Apple Music, Google Play, Amazon Music, Pandora, Last FM, Beatport, Traxsource, Paradise Distribution, Groove Distribution, and more.',
        },
        {
            page: 'store',
            desc:
                'Vizual Records merch and swag including clothing by Threadless, vinyl, accessories, and more.',
        },
        {
            page: 'faq',
            desc:
                'Vizual Records frequently asked questions. Answers to questions about downloading, privacy, accounts, audio formats, and more.',
        },
        {
            page: 'contact',
            desc:
                'Contact Vizual Records with any questions, comments, download issues, or suggestions.',
        },
        {
            page: 'terms',
            desc:
                'Vizual Records site Terms & Conditions and Privacy Policy. Updated regularly.',
        },
        {
            page: 'about',
            desc:
                'Everything you ever wanted to know about Vizual Records: history, mission, website, technologies, people, and the future.',
        },
    ],
    author: 'Vizual Records',
    url: 'https://www.vizualrecords.com/',
    twitter: '@vizualrecords',
    image: 'https://vizualassets.s3.us-east-2.amazonaws.com/vizual_og_2x.jpg?new=20200929',
}
