import React from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { HERO_IMAGES } from '../../graphql/queries'

// use React.memo so Hero doesn't re-render on cart change
const Hero = React.memo(() => {
    const { loading, error, data } = useQuery(HERO_IMAGES)

    if (loading) {
        return (
            <img
                src="https://vizualassets.s3.us-east-2.amazonaws.com/feature_hero_bg.png"
                alt="hero background"
            />
        )
    }
    if (error) return <p>Error :(</p>

    const featured = data.products.nodes

    console.log('featured', featured)

    // get random hero image from featured
    const randomFeatured = featured[Math.floor(Math.random() * featured.length)]
    const hero = randomFeatured.productFields.hero
    // const hero = featured[0].productFields.hero
    const cat = randomFeatured.productCategories.nodes[0].slug
    // const cat = featured[0].productCategories.nodes[0].slug
    const slug = randomFeatured.slug
    // const slug = featured[0].slug

    // console.log('randomFeatured', randomFeatured)

    return (
        <div className="hero-outer flex">
            <div className="hero-inner h-full">
                <Link to={`/catalog/${cat}/${slug}`}>
                    {hero && <img src={hero.sourceUrl} alt={hero.altText} />}
                </Link>
            </div>
        </div>
    )
})

export default Hero
