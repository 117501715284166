import React, { Fragment } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import { Link } from 'react-router-dom'

import { NEW_RELEASES, VIZUAL_CATALOG } from '../../graphql/queries'

import Product from '../Product/Product'
import AlbumsIcon from '../Icons/AlbumsIcon'
import SparklesIcon from '../Icons/SparklesIcon'
import LoadingProduct from '../LoadingProduct/LoadingProduct'

const Featured = React.memo(() => {
    const client = useApolloClient()
    const { loading, error, data } = useQuery(NEW_RELEASES)

    let products
    let productList
    let productListFeatured

    let productData = client.readQuery({
        query: VIZUAL_CATALOG,
        variables: {},
    })

    // console.log('productData', productData)

    if (productData) {
        products = productData.products.edges

        let featured = products.filter((p) => {
            return p.node.featured
        })

        featured = featured.slice(0, 5)

        // console.log('featured from cache', featured)

        productListFeatured = featured.map((item, index) => {
            let product = item.node

            return (
                <li className="product-item" key={product.id}>
                    <Product product={product} />
                </li>
            )
        })
    } else {
        if (error) return <p>Error :(</p>

        if (data) {
            products = data.products.edges

            productList = products.map((item, index) => {
                let product = item.node

                return (
                    <li className="product-item" key={product.id}>
                        <Product product={product} />
                    </li>
                )
            })
        }
    }

    return (
        <Fragment>
            <div className="featured py-8 px-8 sm:px-0 container">
                <h2 className="pb-3 flex flex-col sm:flex-row mb-4 sm:mb-1 items-center">
                    <SparklesIcon />
                    <span className="space-space hidden sm:block">&nbsp;</span>
                    <span className="font-bold text-2xl">
                        Featured Releases
                    </span>
                    <span className="font-semibold text-lg text-gray-500 ml-0 sm:ml-auto hover:text-gray-700">
                        <Link to="/catalog" className="flex items-center">
                            <AlbumsIcon />
                            &nbsp;View All Releases &rarr;
                        </Link>
                    </span>
                </h2>
                <Fragment>
                    {!loading ? (
                        <Fragment>
                            {productListFeatured ? (
                                <ul className="product-list product-list-featured grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 pt-4 mb-4 sm:mb-0">
                                    {productListFeatured
                                        ? productListFeatured
                                        : 'No products loaded.'}
                                </ul>
                            ) : (
                                <ul className="product-list grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 pt-4 mb-4 sm:mb-0">
                                    {productList
                                        ? productList
                                        : 'No products loaded.'}
                                </ul>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {console.log('no products')}
                            <ul className="product-list product-list-featured grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 pt-4 mb-4 sm:mb-0">
                                <LoadingProduct />
                                <LoadingProduct />
                                <LoadingProduct />
                                <LoadingProduct />
                                <LoadingProduct />
                            </ul>
                        </Fragment>
                    )}
                </Fragment>
            </div>
        </Fragment>
    )
})

export default Featured
