import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import CheckoutForm from './CheckoutForm'
import Layout from '../Layout/Layout'
import ScrollToTop from '../ScrollToTop/ScrollToTop'

// Setup Stripe.js and the Elements provider
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_API_KEY)
// const stripePromiseLive = loadStripe(process.env.REACT_APP_STRIPE_LIVE_API_KEY)
const stripePromiseLive = loadStripe('pk_live_APSxZb65ACUVVRw9aDA8e2nP')
// const stripePromiseLive = loadStripe('pk_test_Q8j9ieOEWFZAnuSox9yqNyrG')

const Checkout = ({ localCart, removeFromLocalCart, setLocalCart }) => {
    return (
        <Layout title="Checkout">
            <Elements stripe={stripePromiseLive}>
                <CheckoutForm
                    localCart={localCart}
                    setLocalCart={setLocalCart}
                    removeFromLocalCart={removeFromLocalCart}
                />
            </Elements>
            <ScrollToTop />
        </Layout>
    )
}

export default Checkout
