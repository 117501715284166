import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import parse from 'html-react-parser'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import Meta from '../Meta/Meta'
import Product from '../Product/Product'
import Layout from '../Layout/Layout'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import MockPlayer from '../MockPlayer/MockPlayer'
import Reviews from '../Reviews/Reviews'

// import { site } from '../../helpers/metadata'

import { SINGLE_PRODUCT, TRACKS, UPSELLS } from '../../graphql/queries'

import { AddToCartContext } from '../../App'

const STREAM_URL = 'https://vizualaudio.s3.amazonaws.com/'

const SingleProduct = React.memo(
    ({ togglePlay, currentPlayer, setCurrentPlayer }) => {
        let { slug } = useParams()

        const [showReviews, setShowReviews] = useState(false)

        let product
        let name
        let title
        let catalogNumber
        let description
        let shortDescription
        let tracks
        let numberOfTracks
        let productType
        let playlist
        let products
        let related
        let upsells
        let meta
        let productFields
        let productImage
        let reviews

        const addToLocalCart = React.useContext(AddToCartContext)

        const { loading, error, data } = useQuery(SINGLE_PRODUCT, {
            variables: { slug },
        })

        const { data: trackData } = useQuery(TRACKS, {
            variables: { slug },
        })

        const { data: upsellData } = useQuery(UPSELLS, {
            variables: { slug },
        })

        useEffect(() => {
            if (data) {
                document.title =
                    'Vizual Records - ' + catalogNumber + ' ' + title
            }
        })

        // if (loading) return <p>Loading...</p>
        if (error) return <p>Error :(</p>

        if (data) {
            product = data.products.nodes[0]

            console.log('product in Single Product', product)

            name = product.name

            catalogNumber = name.substr(0, name.indexOf(' '))
            title = name.substr(name.indexOf(' ') + 1)

            description = parse(product.description)
            shortDescription = parse(product.shortDescription)

            // desc = shortDescription[0].props.children

            tracks = product.productFields.tracks
            numberOfTracks = product.productFields.numberOfTracks
            console.log('product tracks', tracks)
            // const artist = product.productFields.artist

            productType = product.productCategories.nodes[0].name

            productFields = product.productFields

            productImage = {
                source: product.image.sourceUrl,
                alt: product.image.altText,
            }

            playlist = tracks.map((track, index) => {
                const trackObject = {
                    id: track.trackProductId,
                    source: `${STREAM_URL}${track.trackAudio}_128.mp3`,
                    trackName: track.trackTitle,
                    trackArtist: track.trackArtist ? track.trackArtist : null,
                }
                return trackObject
            })

            meta = {
                title: name,
                num: null,
                desc: shortDescription,
                slug: slug,
            }

            reviews = product.productFields.reviews
        }

        console.log('trackData in singleProduct', trackData)

        if (trackData) {
            products = trackData.products.nodes
        }

        if (upsellData) {
            console.log('upsell data', upsellData)
            related = upsellData.products.nodes[0].upsell.nodes

            upsells = related.map((item, index) => {
                // console.log('upsell item', item)
                return (
                    <li className="product-item" key={item.id}>
                        <Product product={item} />
                    </li>
                )
            })
        }

        return (
            <SimpleReactLightbox>
                <Meta meta={meta} />
                <Layout title={title && title}>
                    <div className="nav-link text-sky-600 sm:p-0 md:p-0">
                        <Link to="/catalog">&larr; Back to Catalog</Link>
                    </div>
                    {loading && (
                        <div className="loading sm:p-0 mt-4 mb-4 text-xl font-semibold text-gray-500 flex items-center">
                            <span className="mr-3">Loading...</span>
                            <div className="spinner">
                                <div className="bounce1 bg-grey-500"></div>
                                <div className="bounce2 bg-grey-500"></div>
                                <div className="bounce3 bg-grey-500"></div>
                            </div>
                        </div>
                    )}
                    {error && (
                        <div className="data-error">
                            Sorry, the product couldn't be loaded. Try
                            refreshing the page.
                        </div>
                    )}
                    {data && (
                        <div className="single-product mt-4 mb-6 sm:p-0 md:p-0 md:px-0">
                            <div className="product-outer mt-3 mb-6 border border-stone-200 rounded-sm relative">
                                <h1 className="text-xl sm:text-2xl font-medium bg-stone-100 p-5 text-zinc-600 sm:px-6 ">
                                    <span className="catalog-number text-zinc-400">
                                        {catalogNumber && catalogNumber}
                                    </span>
                                    &nbsp;
                                    <span className="product-title font-bold">
                                        {title && title}
                                    </span>
                                </h1>
                                <div className="product-inner flex items-start flex-wrap relative flex-col sm:flex-row">
                                    <div className="product-image lg:w-3/12 sm:pt-6 sm:pb-6 sm:pl-6 sm:pr-3 sm:w-5/12 w-full p-6">
                                        <SRLWrapper>
                                            <a
                                                href={`https://vizualassets.s3.us-east-2.amazonaws.com/${
                                                    product.productFields
                                                        ? product.productFields
                                                              .cat + '.png'
                                                        : product.image
                                                              .sourceUrl
                                                }`}
                                            >
                                                <img
                                                    loading="lazy"
                                                    src={
                                                        product.image.sourceUrl
                                                    }
                                                    alt={product.image.altText}
                                                />
                                            </a>
                                        </SRLWrapper>
                                    </div>

                                    <div className="product-content xl:w-6-12 lg:w-5/12 sm:w-6/12 w-full sm:pt-6 sm:pb-6 sm:pl-3 lg:pr-6 p-6 pt-0 max-w-3xl ">
                                        {description
                                            ? description
                                            : shortDescription}

                                        {reviews && reviews.length > 0 && (
                                            <p
                                                className="reviews-trigger underline text-sm text-sky-600 cursor-pointer"
                                                onClick={() =>
                                                    setShowReviews(!showReviews)
                                                }
                                            >
                                                {`${
                                                    showReviews
                                                        ? 'Hide '
                                                        : 'See '
                                                }`}
                                                reviews
                                            </p>
                                        )}
                                    </div>
                                    <div className="product-purchase bg-stone-50 p-6 xl:w-3/12 lg:w-4/12 sm:w-full h-full lg:absolute right-0  relative">
                                        <div className="product-meta">
                                            <p className="text-sm">
                                                Full {productType.toLowerCase()}{' '}
                                                comes with full-quality audio
                                                (AIFF) and high-resolution cover
                                                art.
                                            </p>
                                            <p className="text-sm">
                                                {numberOfTracks}-track{' '}
                                                {productType.toLowerCase()}
                                            </p>
                                        </div>
                                        <div className="product-price text-xl font-bold text-zinc-600 mt-3 mb-2">
                                            {product.price}
                                        </div>
                                        <button
                                            type="button"
                                            className="button playlist-button bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded mt-3 mb-3"
                                            onClick={() => {
                                                addToLocalCart({ product })
                                            }}
                                        >
                                            Add {productType} To Cart
                                        </button>
                                        <div className="product-individual-tracks pt-5 pb-5 text-zinc-500">
                                            <p className="text-xs">
                                                Individual tracks can be added
                                                to your cart below.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showReviews && reviews && reviews.length && (
                                <Reviews
                                    reviews={reviews}
                                    title={title && title}
                                    setShowReviews={setShowReviews}
                                    product={product}
                                    productType={productType}
                                    addToLocalCart={addToLocalCart}
                                />
                            )}
                            <div className="product-playlist-outer border border-stone-200 rounded-sm">
                                <h2 className="text-xl text-zinc-600 font-bold bg-stone-100 p-6">
                                    Tracks
                                </h2>
                                <div className="product-playlist-inner px-6">
                                    <MockPlayer
                                        playlist={playlist}
                                        products={products}
                                        productFields={productFields}
                                        productImage={productImage}
                                    />
                                </div>
                            </div>
                            {upsells && upsells.length ? (
                                <div className="upsells my-8 p-6 bg-stone-100 rounded-sm">
                                    <h3 className="font-bold text-xl text-zinc-500 mb-3">
                                        You may also like:
                                    </h3>
                                    <ul className="product-list upsell-list nostyle grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4">
                                        {upsells}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    )}
                    <ScrollToTop />
                </Layout>
            </SimpleReactLightbox>
        )
    }
)

export default SingleProduct
