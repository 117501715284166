/* eslint-disable */

export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    )
}

export function decodeEntities(encodedString) {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g
    var translate = {
        nbsp: ' ',
        amp: '&',
        quot: '"',
        lt: '<',
        gt: '>',
    }
    return encodedString
        .replace(translate_re, function (match, entity) {
            return translate[entity]
        })
        .replace(/&#(\d+);/gi, function (match, numStr) {
            var num = parseInt(numStr, 10)
            return String.fromCharCode(num)
        })
}

export function slugify(string) {
    const a =
        'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b =
        'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}

export const SIZE_ARRAY = [
    {
        sizeSlug: 'small',
        size: 'S',
    },
    {
        sizeSlug: 'medium',
        size: 'M',
    },
    {
        sizeSlug: 'large',
        size: 'L',
    },
    {
        sizeSlug: 'x-large',
        size: 'XL',
    },
    {
        sizeSlug: '2x-large',
        size: '2XL',
    },
]

export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
}

export function groupBy(list, keyGetter) {
    const map = new Map()
    list.forEach((item) => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
            map.set(key, [item])
        } else {
            collection.push(item)
        }
    })
    return map
}

export function fetchFromObject(obj, prop) {
    if (typeof obj === 'undefined') {
        return false
    }

    var _index = prop.indexOf('.')
    if (_index > -1) {
        return fetchFromObject(
            obj[prop.substring(0, _index)],
            prop.substr(_index + 1)
        )
    }

    return obj[prop]
}

export const firstDateIsPastDayComparedToSecond = (firstDate, secondDate) =>
    firstDate.setHours(0, 0, 0, 0) - secondDate.setHours(0, 0, 0, 0) < 0
