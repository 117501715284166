import React from 'react'
import { Helmet } from 'react-helmet'

import { site } from '../../helpers/metadata'

const Meta = React.memo(({ meta }) => {
    let desc

    if (!meta || !meta.num) {
        return null
    }

    if (meta.num === null || meta.num === undefined) {
        desc = meta.desc[0].props.children
    } else {
        desc = site.pageDescriptions[meta.num].desc
    }

    return (
        <Helmet>
            <title>Vizual Records - {meta.title}</title>
            <meta name="description" content={desc} />
            {/* Facebook Open Graph */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://www.vizualrecords.com/${meta.slug}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${site.title} - ${meta.title}`} />
            <meta property="og:image" content={site.image} />
            <meta property="og:image:alt" content="Vizual Records" />
            <meta property="og:description" content={desc} />
            <meta property="og:site_name" content={site.title} />
            <meta property="og:locale" content="en_US" />
            <meta property="article:author" content={site.title} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={`${site.url}/${meta.slug}`} />
            <meta
                property="twitter:title"
                content={`${site.title} - ${meta.title}`}
            />
            <meta property="twitter:description" content={desc} />
            <meta property="twitter:image" content={site.image} />
            <a rel="me" href="https://mastodon.social/@joshuaiz">Mastodon</a>
        </Helmet>
    )
})

export default Meta
