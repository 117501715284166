import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import ClothingItem from './ClothingItem'
import ClothingInfo from './ClothingInfo'
import ThreadlessLogo from '../Logos/ThreadlessLogo'
import TShirtIcon from '../Icons/TShirtIcon'
import clothingDataJson from '../../data/clothingData.json'

import { CLOTHING } from '../../graphql/queries'

const Clothing = React.memo(() => {
    const { data, loading, error } = useQuery(CLOTHING)
    const [clothing, setClothing] = useState()
    const [stillLoading, setStillLoading] = useState(false)

    let clothingList

    // delay showing loading spinner to avoid initial flash
    setTimeout(() => {
        if (loading) {
            setStillLoading(true)
        } else {
            setStillLoading(false)
        }
    }, 1000)

    // console.log('data from clothing query', data)

    if (data) {
    }

    useEffect(() => {
        // get clothing data from local json first
        if (clothingDataJson) {
            // console.log('data in clothing', data)
            let clothingData = clothingDataJson.data.clothing.nodes
            setClothing(clothingData)
            // console.log('clothingData JSON', clothingData)
        } else if (data) {
            let clothingData = data.clothing.nodes
            setClothing(clothingData)
        }
    }, [data])

    if (clothing) {
        clothingList = clothing.map((item, index) => {
            // console.log('clothing item', item)
            return <ClothingItem item={item} key={item.id} />
        })
    }

    return (
        <div
            id="clothing"
            className="overflow-visible clothing store-section pt-2 border-t border-gray-200 pb-5"
        >
            <h2 className="section-title text-3xl font-bold mt-2 mb-4 flex flex-col sm:flex-row items-center tracking-tight">
                <span className="t-shirt-icon w-6 h-6 mr-1 mt-2">
                    <TShirtIcon />
                </span>
                Clothing by{' '}
                <span className="threadless-logo w-40 block mb-4 sm:mb-0">
                    <ThreadlessLogo />
                </span>
            </h2>
            {stillLoading && loading && !clothing && (
                <div className="loading px-6 sm:p-0 mt-4 mb-4 text-xl font-semibold text-gray-700 flex items-center">
                    <span className="mr-3">Loading...</span>
                    <div className="spinner">
                        <div className="bounce1 bg-grey-800"></div>
                        <div className="bounce2 bg-grey-800"></div>
                        <div className="bounce3 bg-grey-800"></div>
                    </div>
                </div>
            )}
            {error && (
                <div className="data-error">
                    Sorry, the page couldn't be loaded. Try refreshing.
                </div>
            )}
            {clothing && (
                <ul className="clothing-list nostyle grid grid-cols-4 row-gap-8 gap-8 col-gap-16 overflow-visible">
                    {clothingList.reverse()}
                </ul>
            )}
            <ClothingInfo />
        </div>
    )
})

export default Clothing
