import React from 'react'

const MinusCircleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="svg-inline--fa fa-minus-circle fa-w-16 w-5 h-auto"
            data-icon="minus-circle"
            data-prefix="fas"
            viewBox="0 0 512 512"
        >
            <path
                fill="currentColor"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z"
            ></path>
        </svg>
    )
}

export default MinusCircleIcon
