import React, { useState, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import GlobalPlayer from './components/GlobalPlayer/GlobalPlayer'
import Home from './components/Home/Home'
import Header from './components/Header/Header'
import SingleProduct from './components/SingleProduct/SingleProduct'
import Catalog from './components/Catalog/Catalog'
import Artists from './components/Artists/Artists'
import Store from './components/Store/Store'
import Outlets from './components/Outlets/Outlets'
import Page from './components/Page/Page'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import Checkout from './components/Checkout/Checkout'
import PageNotFound from './components/PageNotFound/PageNotFound'
import FormSuccess from './components/FormSuccess/FormSuccess'
import DLifeSuccess from './components/DLifeSuccess/DLifeSuccess'
import DLife from './components/DLife/DLife'
// import Loading from './components/Loading/Loading'
import './App.scss'

import { VIZUAL_CATALOG } from './graphql/queries'

export const AddToCartContext = React.createContext(null)
export const RemoveFromCartContext = React.createContext(null)
export const AudioContext = React.createContext(null)

const App = () => {
    const [localCart, setLocalCart] = useState([])
    const { data } = useQuery(VIZUAL_CATALOG)

    const [audioContext, setAudioContext] = useState(null)

    let products

    const addToLocalCart = useCallback(
        ({ product }) => {
            console.log('addToLocalCart', product)
            if (!product || !product.id) {
                console.error(
                    'addToLocalCart: No product or product.id',
                    product
                )
                return false
            }
            let newCart = []
            // if we already have item(s) in the cart
            if (localCart && localCart.length) {
                // check if item is already in the cart
                const checkId = (obj) => obj.id === product.id
                // if it is, alert the customer
                if (localCart.some(checkId)) {
                    alert(
                        'The item you are trying to add is already in your cart!'
                    )
                    return false
                }
                // if not, add it to cart array + update localCart
                newCart = [...localCart, product]
                setLocalCart(newCart)
                localStorage.setItem('vizual_cart', JSON.stringify(newCart))
            } else {
                // nothing is in the cart so add our product.
                newCart = [...localCart, product]
                setLocalCart(newCart)
                localStorage.setItem('vizual_cart', JSON.stringify(newCart))
            }
        },
        [localCart]
    )

    const removeFromLocalCart = useCallback(
        (product) => {
            // return a new cart array with everything but the removed item
            const newCart = localCart.filter((p) => {
                return p.id !== product.item.id
            })
            localStorage.setItem('vizual_cart', JSON.stringify(newCart))
            setLocalCart(newCart)
        },
        [localCart]
    )

    const getLocalCart = () => {
        // check to see if there is a cart in localStorage
        const cachedCart = localStorage.getItem('vizual_cart')
        // if so, use cached
        if (cachedCart && cachedCart.length !== 0) {
            setLocalCart(JSON.parse(cachedCart))
            // console.log('Cart: Using cached!')
        }
    }

    useEffect(() => {
        getLocalCart()
    }, [])

    if (data) {
        console.log('we have data', data)
        products = data.products.edges
    }

    return (
        <div className="App-outer vizualrecords-outer">
            <div className="App-inner vizualrecords-inner">
                <AudioContext.Provider value={[audioContext, setAudioContext]}>
                    <Router>
                        <RemoveFromCartContext.Provider
                            value={removeFromLocalCart}
                        >
                            <Header localCart={localCart} />
                        </RemoveFromCartContext.Provider>
                        <AddToCartContext.Provider value={addToLocalCart}>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route
                                    path="/catalog"
                                    element={<Catalog products={products} />}
                                />

                                <Route
                                    path="/catalog/:category/:slug"
                                    element={<SingleProduct />}
                                />
                                <Route path="/artists" element={<Artists />} />
                                <Route path="/store" element={<Store />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/outlets" element={<Outlets />} />
                                <Route
                                    path="/success"
                                    element={<FormSuccess />}
                                />
                                <Route
                                    path="/d-life-success"
                                    element={<DLifeSuccess />}
                                />
                                <Route
                                    path="/checkout"
                                    element={
                                        <Checkout
                                            localCart={localCart}
                                            setLocalCart={setLocalCart}
                                            removeFromLocalCart={
                                                removeFromLocalCart
                                            }
                                        />
                                    }
                                />
                                <Route path="/about" element={<Page />} />
                                <Route path="/faq" element={<Page />} />
                                <Route path="/terms" element={<Page />} />
                                <Route path="/d-life" element={<DLife />} />
                                <Route path="*" element={<PageNotFound />} />
                            </Routes>
                        </AddToCartContext.Provider>
                        <Footer products={products} />
                    </Router>
                    <AddToCartContext.Provider value={addToLocalCart}>
                        <GlobalPlayer />
                    </AddToCartContext.Provider>
                </AudioContext.Provider>
            </div>
        </div>
    )
}

export default App
