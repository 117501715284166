import React from 'react'

const LoadingProduct = () => {
    return (
        <li className="product-item bg-stone-50 h-[558px] border border-gray-50">
            <div className="product-square bg-stone-200 w-full aspect-square load-block"></div>
            <div className="product-content-wrap p-6 flex flex-col gap-2">
                <div className="cat-number-block bg-stone-200 w-16 h-4 load-block"></div>
                <div className="title-block bg-stone-300 w-24 h-4 load-block"></div>
                <div className="release-block bg-stone-300 w-32 h-5 load-block"></div>
                <div className="spacer h-2"></div>
                <div className="cat-number-block bg-stone-200 w-16 h-4 load-block"></div>
                <div className="spacer h-2"></div>
                <div className="desc-block bg-stone-200 w-full h-12 load-block"></div>
                <div className="spacer h-1"></div>
                <div className="price-block bg-stone-200 w-16 h-4 load-block"></div>
                <div className="spacer h-1"></div>
                <div className="atc-block bg-stone-300 w-full h-10 load-block"></div>
            </div>
        </li>
    )
}

export default LoadingProduct
