import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useApolloClient } from '@apollo/client'

import { ALBUMS, VIZUAL_CATALOG } from '../../graphql/queries'

import Product from '../Product/Product'
import AlbumsIcon from '../Icons/AlbumsIcon'
import LoadingProduct from '../LoadingProduct/LoadingProduct'

const Albums = React.memo(() => {
    const client = useApolloClient()
    const { loading, error, data } = useQuery(ALBUMS)

    let products
    let productList
    let productListAlbums

    const productData = client.readQuery({
        query: VIZUAL_CATALOG,
        variables: {},
    })

    // console.log('productData', productData)

    if (productData) {
        products = productData.products.edges

        let albums = products.filter((p) => {
            return p.node.productCategories.nodes[0].name === 'Album'
        })

        let shuffledAlbums = albums
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)

        shuffledAlbums = shuffledAlbums.slice(0, 5)

        // console.log('shuffledAlbums', shuffledAlbums)

        productListAlbums = shuffledAlbums.map((item, index) => {
            let product = item.node

            return (
                <li className="product-item" key={product.id}>
                    <Product product={product} />
                </li>
            )
        })
    } else {
        // if (loading) return null
        if (error) return <p>Error :(</p>

        if (data) {
            products = data.products.edges

            productList = products.map((item, index) => {
                let product = item.node

                return (
                    <li className="product-item" key={product.id}>
                        <Product product={product} />
                    </li>
                )
            })
        }
    }

    return (
        <div className="featured py-8 px-8 sm:px-0 container">
            <h2 className="pb-3 flex flex-col sm:flex-row items-center">
                <AlbumsIcon />
                <span className="space-space hidden sm:block">&nbsp;</span>
                <span className="font-bold text-2xl text-zinc-600">
                    Featured Albums
                </span>
                <span className="font-semibold text-lg text-gray-500 ml-0 sm:ml-auto hover:text-gray-700">
                    <Link to="/catalog" className="flex items-center">
                        <AlbumsIcon />
                        &nbsp;View All Albums &rarr;
                    </Link>
                </span>
            </h2>
            <Fragment>
                {!loading ? (
                    <Fragment>
                        {productListAlbums ? (
                            <ul className="product-list product-list-albums grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 pt-4 mb-4 sm:mb-0">
                                {productListAlbums
                                    ? productListAlbums
                                    : 'No products loaded.'}
                            </ul>
                        ) : (
                            <ul className="product-list grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 pt-4 mb-4 sm:mb-0">
                                {productList
                                    ? productList
                                    : 'No products loaded.'}
                            </ul>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        {console.log('no products')}
                        <ul className="product-list product-list-featured grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 pt-4 mb-4 sm:mb-0">
                            <LoadingProduct />
                            <LoadingProduct />
                            <LoadingProduct />
                            <LoadingProduct />
                            <LoadingProduct />
                        </ul>
                    </Fragment>
                )}
            </Fragment>
        </div>
    )
})

export default Albums
