import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../Layout/Layout'

import { site } from '../../helpers/metadata'

const DLife = () => {
    const currentYear = new Date().getFullYear()
    const image =
        'https://vizualassets.s3.us-east-2.amazonaws.com/d-life-fb-event-cover-1920x1005_lineup_v5.jpg'
    return (
        <Fragment>
            <Helmet>
                <meta
                    property="twitter:image"
                    content="https://vizualassets.s3.us-east-2.amazonaws.com/d-life-fb-event-cover-1920x1005_lineup_v5.jpg?4362984378"
                />
                <meta
                    property="og:image"
                    content="https://vizualassets.s3.us-east-2.amazonaws.com/d-life-fb-event-cover-1920x1005_lineup_v5.jpg?new=12345"
                />
                <meta
                    name="description"
                    content="D-Life returns to MotorCity Wine on Sunday, May 29th, 2022 with an all-star exclusive lineup featuring Kenny Larkin, Mike Dunn, Iz & Diz, Rick Wade."
                />
                {/* Facebook Open Graph */}
                <meta
                    property="og:url"
                    content="https://www.vizualrecords.com/d-life"
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Vizual Records - D-Life" />

                <meta property="og:image:alt" content="D-Life 2022 flyer" />
                <meta
                    property="og:description"
                    content="D-Life returns to MotorCity Wine on Sunday, May 29th, 2022 with an all-star exclusive lineup featuring Kenny Larkin, Mike Dunn, Iz & Diz, Rick Wade."
                />
                <meta
                    property="og:site_name"
                    content="Vizual Records - D-Life"
                />
                <meta property="og:locale" content="en_US" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="article:author" content="@joshuaiz" />

                <meta
                    property="twitter:url"
                    content="https://www.vizualrecords.com/"
                />
                <meta
                    property="twitter:title"
                    content="Vizual Records - D-Life"
                />
                <meta
                    property="twitter:description"
                    content="D-Life returns to MotorCity Wine on Sunday, May 29th, 2022 with an all-star exclusive lineup featuring Kenny Larkin, Mike Dunn, Iz & Diz, Rick Wade."
                />

                <title>Vizual Records - D-Life 2022</title>
            </Helmet>
            <div className="hero w-full">
                <div className="desktop-hero-image hidden lg:block">
                    <img
                        src="https://vizualassets.s3.us-east-2.amazonaws.com/d-life-hero-lineup-v2-KENNY.png"
                        alt="D-Life hero with full lineup, address, and QR code"
                    />
                </div>
                <div className="desktop-mobile-image block lg:hidden">
                    <img
                        src="https://vizualassets.s3.us-east-2.amazonaws.com/d-life_lineup_RA_2022_v7-KENNY.jpg"
                        alt="D-Life hero with full lineup, address, and QR code"
                    />
                </div>
            </div>
            <Layout>
                <div className="page-outer d-life-outer px-4 xl:px-0 w-full lg:w-7/12 m-auto">
                    <div className="page-inner d-life-inner">
                        <div className="page-header">
                            <h1 className="page-title text-3xl font-extrabold">
                                D-Life&nbsp;{currentYear}{' '}
                            </h1>
                            <h2 className="text-2xl text-stone-600 font-semibold">
                                Sunday, May 29th
                                <br />
                                2:00pm–2:00am (day{' '}
                                <span role="img" aria-label="heart emoji">
                                    ❤️
                                </span>{' '}
                                night)
                                <br />
                            </h2>
                            <h3 className="text-xl font-semibold mt-4">
                                <a
                                    href="https://ra.co/events/1504749"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Advance Tickets (RA) &rarr;
                                </a>
                            </h3>
                        </div>
                        <div className="page-content">
                            <div className="intro my-6 w-full lg:w-7/12">
                                <p className="text-xl font-semibold text-stone-600">
                                    After a 2-year hiatus, Vizual Records and
                                    Eastcoast Underground present the return of
                                    D-Life at MotorCity Wine in 2022 featuring
                                    an all-star 100% exclusive lineup.
                                </p>
                                <p className="text-xl font-semibold text-stone-600"></p>
                            </div>
                            <div className="email-signup w-full lg:w-1/2">
                                <form
                                    className="flex items-center justify-between w-full gap-4 flex-wrap lg:flex-nowrap"
                                    name="d-life"
                                    method="post"
                                    action="/d-life-success"
                                    netlify-honeypot="bot-field"
                                    data-netlify="true"
                                >
                                    <input
                                        type="hidden"
                                        name="form-name"
                                        value="d-life"
                                    />
                                    <input type="hidden" name="bot-field" />
                                    <div className="email-signup-input flex flex-col w-full">
                                        <label htmlFor="email">
                                            Email Address:
                                        </label>
                                        <input
                                            className="h-12 rounded-md bg-stone-50 w-full border px-4 border-stone-200 min-w-[240px]"
                                            type="email"
                                            id="email"
                                            name="email"
                                            required
                                        />
                                    </div>
                                    <div className="email-signup-button ml-0 lg:ml-2">
                                        {/* <button
                                            type="submit"
                                            className="button playlist-button bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded w-80 mt-0 lg:mt-6 h-12 max-w-96"
                                        >
                                            Sign Up
                                        </button> */}
                                    </div>
                                </form>
                                <p className="text-base my-2 text-stone-600 font-semibold">
                                    Enter your email address above to get D-Life
                                    updates and exclusive offers in your inbox.
                                </p>
                                <p className="text-xs my-2 text-stone-400">
                                    *We respect your privacy and do not share or
                                    sell email addresses to anyone ever. We'll
                                    only email you with D-Life-related news and
                                    offers.
                                </p>
                            </div>
                            <div className="lineup-outer w-full lg:w-1/2 my-6">
                                <div className="lineup-inner">
                                    <h2 className="text-base font-semibold text-stone-600">
                                        Featuring 100% Exclusive Lineup:
                                    </h2>
                                    <div className="lineup-artist my-4">
                                        <h3 className="text-2xl font-bold text-stone-800">
                                            Kenny Larkin
                                        </h3>
                                        <p className="pt-0">
                                            (Warp, Planet E, Transmat,
                                            Detroit/LA)
                                        </p>
                                    </div>

                                    <div className="lineup-artist my-4">
                                        <h3 className="text-2xl font-bold text-stone-800">
                                            Mike Dunn
                                        </h3>
                                        <p className="pt-0">
                                            (Classic, Defected, Chicago)
                                        </p>
                                    </div>

                                    <div className="lineup-artist my-4">
                                        <h3 className="text-2xl font-bold text-stone-800">
                                            Iz & Diz
                                        </h3>
                                        <p className="pt-0">
                                            (Vizual, Classic, Chicago)
                                        </p>
                                    </div>

                                    <div className="lineup-artist my-4">
                                        <h3 className="text-2xl font-bold text-stone-800">
                                            Rick Wade
                                        </h3>
                                        <p className="pt-0">
                                            (Harmonie Park, Detroit)
                                        </p>
                                    </div>

                                    <div className="lineup-artist my-4">
                                        <h3 className="text-2xl font-bold text-stone-800">
                                            Pjay [Live]
                                        </h3>
                                        <p className="pt-0">(NYEX, NYC)</p>
                                    </div>

                                    <div className="lineup-artist my-4">
                                        <h3 className="text-2xl font-bold text-stone-800">
                                            Juano Sosa
                                        </h3>
                                        <p className="pt-0">(Chicago)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="address">
                                <p className="font-semibold text-stone-500">
                                    Amazing Sound{' '}
                                    <span role="img" aria-label="heart emoji">
                                        ❤️
                                    </span>{' '}
                                    Outdoor Patio{' '}
                                    <span role="img" aria-label="heart emoji">
                                        ❤️
                                    </span>{' '}
                                    Food Truck
                                </p>
                                <p className="mt-4">
                                    <a href="https://motorcitywine.com/">
                                        MotorCity Wine
                                    </a>
                                    <br />
                                    1949 Michigan Ave
                                    <br />
                                    Detroit, MI 48216
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </Fragment>
    )
}

export default DLife
