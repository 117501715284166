import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Meta from '../Meta/Meta'
import Layout from '../Layout/Layout'
import Clothing from '../Clothing/Clothing'
import Vinyl from '../Vinyl/Vinyl'

const meta = {
    title: 'Store',
    num: 3,
    slug: 'store',
}

const Store = React.memo(() => {
    return (
        <Fragment>
            <Meta meta={meta} />
            <Layout>
                <div className="store-page">
                    <div className="store-inner">
                        <div className="page-header pb-4">
                            <h1 className="page-title text-4xl font-bold mt-2 mb-4 flex items-center tracking-tight">
                                Store
                            </h1>
                            <p className="text-base text-gray-600">
                                <a href="#clothing">Clothing</a>,{' '}
                                <a href="#vinyl">vinyl</a>, and everything else
                                that's not digital music. All digital music can
                                be found on the{' '}
                                <Link to="/catalog">Catalog</Link> page and
                                respective release pages.
                            </p>
                        </div>
                        <Clothing />
                        <Vinyl />
                    </div>
                </div>
            </Layout>
        </Fragment>
    )
})

export default Store
