import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import parse from 'html-react-parser'

import Meta from '../Meta/Meta'

import { groupBy } from '../../helpers/helpers'

import Layout from '../Layout/Layout'

import { OUTLETS } from '../../graphql/queries'

const meta = {
    title: 'Outlets',
    num: 2,
    slug: 'outlets',
}

const Outlets = React.memo(() => {
    const { loading, error, data } = useQuery(OUTLETS)

    let outlets
    let outletLists
    let grouped

    if (data) {
        // console.log('data in outlets', data)

        outlets = data.vizualOutlets.nodes

        grouped = groupBy(
            outlets,
            (outlet) => outlet.outletCategories.nodes[0].slug
        )

        const direct = grouped.get('direct')
        const distributors = grouped.get('distributor')
        const streaming = grouped.get('streaming-service')
        const music = grouped.get('digital-music-outlet')

        const outletGroups = [direct, streaming, music, distributors]

        outletLists = outletGroups.map((g) => {
            // console.log('group', g)
            return (
                <div
                    className="outlet-group mt-4 mb-4 pb-12 pt-3 border-t border-gray-200"
                    key={g[0].outletCategories.nodes[0].id}
                >
                    <h2 className="text-2xl text-gray-900 mb-2 font-bold tracking-tight">
                        {g[0].outletCategories.nodes[0].name}s
                    </h2>
                    {g[0].outletCategories.nodes[0].name === 'Direct' && (
                        <div className="direct-intro max-w-2xl">
                            <p>
                                The best place to buy Vizual releases is at the{' '}
                                <Link to="/">Vizual Store</Link>. Releases will
                                be here first and often contain exclusive mixes
                                not found on any other outlet. Buying from the
                                Vizual Store also puts more money directly in
                                the hands of the artists so they can continue to
                                make the music you love.
                            </p>
                        </div>
                    )}
                    <ul className="grid grid-cols-tiles gap-6 mt-6">
                        {g
                            .map((o, i) => {
                                return (
                                    <li className="outlet" key={o.id}>
                                        <div className="outlet-image">
                                            <img
                                                src={
                                                    o.outletFields.image
                                                        .mediaItemUrl
                                                }
                                                alt={
                                                    o.outletFields.image.altText
                                                }
                                            />
                                        </div>
                                        <div className="outlet-content">
                                            <a
                                                href={o.outletFields.link.url}
                                                target={
                                                    o.outletFields.link.target
                                                }
                                            >
                                                <span className="leading-tight text-sm block mt-1">
                                                    {o.outletFields.link.title}
                                                </span>
                                            </a>
                                            {o.outletFields.text && (
                                                <div className="text-sm my-2 text-gray-700 leading-tight">
                                                    {parse(o.outletFields.text)}
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                )
                            })
                            .reverse()}
                    </ul>
                </div>
            )
        })
    }

    return (
        <Fragment>
            <Meta meta={meta} />
            <Layout title="Outlets">
                <div className="outlets">
                    <div className="page-header">
                        <h1 className="page-title text-4xl font-bold mt-2 mb-4">
                            Outlets
                        </h1>
                    </div>
                    {loading && (
                        <div className="loading px-6 sm:p-0 mt-4 mb-4 text-xl font-semibold text-gray-500 flex items-center">
                            <span className="mr-3">Loading...</span>
                            <div className="spinner">
                                <div className="bounce1 bg-grey-500"></div>
                                <div className="bounce2 bg-grey-500"></div>
                                <div className="bounce3 bg-grey-500"></div>
                            </div>
                        </div>
                    )}
                    {error && (
                        <div className="data-error">
                            Sorry, the page couldn't be loaded. Try refreshing.
                        </div>
                    )}
                    <div className="outlets-groups">
                        {outletLists && outletLists}
                    </div>
                </div>
            </Layout>
        </Fragment>
    )
})

export default Outlets
