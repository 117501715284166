import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import CustomAutocomplete from '../Search/Autocomplete'

const searchClient = algoliasearch(
    '6XMSKS0GU2',
    'cc99cb1d93ada701a070d96b3a16b2ae'
)

const Search = ({ isSearching, handleResultClick, handleIsSearching }) => {
    return (
        <div
            className={`search-outer flex bg-gray-400 py-2 transition duration-500 ease-in-out z-50 ${
                isSearching
                    ? 'search-open block sticky top-0 h-auto'
                    : 'search-closed hidden h-0'
            }`}
        >
            <div
                className={`search-inner bg-gray-100 lg:w-6/12 mx-auto rounded-md w-9/12 sm:w-8/12 md:w-7/12`}
            >
                <InstantSearch
                    searchClient={searchClient}
                    indexName="vizual_products"
                >
                    <Configure hitsPerPage={12} />
                    <CustomAutocomplete
                        handleResultClick={handleResultClick}
                        handleIsSearching={handleIsSearching}
                        isSearching={isSearching}
                    />
                </InstantSearch>
            </div>
        </div>
    )
}

export default Search
