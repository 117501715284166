import React from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import parse from 'html-react-parser'

import Layout from '../Layout/Layout'
import Sidebar from '../Sidebar/Sidebar'
import ScrollToTop from '../ScrollToTop/ScrollToTop'

import { PAGE } from '../../graphql/queries'

const Page = React.memo(() => {
    let location = useLocation()

    const slug = location.pathname.substring(1)
    // console.log('slug, location', slug, location)
    const id = slug

    const { loading, error, data } = useQuery(PAGE, {
        variables: { id },
    })

    let useSidebar = false

    let title
    let content

    if (data && data.page) {
        title = parse(data.page.title)
        content = parse(data.page.content)
    }

    if (error) {
        console.log('page error', error)
    }

    return (
        <Layout>
            <div className="page">
                {loading && (
                    <div className="loading px-6 sm:p-0 mt-4 mb-4 text-xl font-semibold text-gray-500">
                        Loading...
                    </div>
                )}
                {error && (
                    <div className="data-error">
                        Sorry, this page couldn't be loaded. Try refreshing.
                    </div>
                )}
                {data && (
                    <div
                        className={`page-outer flex ${
                            useSidebar
                                ? 'justify-between'
                                : 'justify-center m-auto'
                        } mt-2`}
                    >
                        <div className="page-inner w-full md:w-7/12">
                            <h1 className="page-title text-4xl font-bold">
                                {title && title}
                            </h1>
                            <div className="page-content">
                                {content && content}
                            </div>
                        </div>
                        {useSidebar && <Sidebar />}
                    </div>
                )}
            </div>
            <ScrollToTop />
        </Layout>
    )
})

export default Page
