import React, { useState, Fragment } from 'react'
import CartDrawer from '../CartDrawer/CartDrawer'

import { RemoveFromCartContext } from '../../App'

const MEDIA_URL = 'https://vizualassets.s3.us-east-2.amazonaws.com/'

const Cart = React.memo(({ localCart }) => {
    if (!localCart || localCart.length === 0 || localCart === '[null]') {

    }
    const [isOpen, setIsOpen] = useState(false)
    const [isRemoving, setIsRemoving] = useState(false)

    const removeFromLocalCart = React.useContext(RemoveFromCartContext)

    // console.log('localCart in Cart.js', localCart)
    const numProducts = localCart.length

    // get cart total using map + reduce
    const add = (a, b) => parseFloat(a) + parseFloat(b)

    let total = 0

    if (localCart && localCart.length) {
        total = localCart
        .map((item) => item.price.replace(/[^0-9.-]+/g, ''))
        .reduce(add, 0)

        total = parseFloat(total).toFixed(2)
    }

    const handleCartDrawer = () => {
        setIsOpen(!isOpen)
    }

    const handleRemoveFromCart = ({ item }) => {
        setIsRemoving(true)
        var element = document.getElementById(item.id)
        // console.log('element', element)
        element.classList.add('fadeOut')
        setTimeout(() => {
            setIsRemoving(false)
            if (!isRemoving) {
                removeFromLocalCart({ item })
                element.classList.remove('fadeOut')
            }
        }, 800)
    }

    const cartList = localCart.map((item, index) => {
        let slug = item.sku.split('-')[0]
        let thumbnailSrc = `${MEDIA_URL}${slug}-100x100.png`
        return (
            <li
                key={`cart-product-${item.id}`}
                className={`cart-product text-left flex w-full sm:w-auto items-center text-gray-100 text-xs mt-1 mb-4 px-1 leading-tight`}
                id={`${item.id}`}
            >
                <div className="product-image w-6 mr-2">
                    <img src={thumbnailSrc} alt={item.name} />
                </div>
                <div className="product-info mr-2 flex w-full">
                    <span className="product-quantity">1</span>
                    &nbsp;
                    <span className="cart-name">
                        {item.name && item.name
                            ? item.name
                            : item.title && item.title}
                    </span>
                    <span className="cart-price ml-auto px-2">
                        $
                        {parseFloat(
                            Number(item.price.replace(/[^0-9.-]+/g, ''))
                        ).toFixed(2)}
                    </span>
                </div>

                <span
                    className="close-x text-red-900 text-base cursor-pointer ml-auto hover:text-red-600 w-3"
                    // onClick={() => {
                    //     removeFromLocalCart({ item })
                    // }}
                    onClick={() => {
                        handleRemoveFromCart({ item })
                    }}
                    title="Remove"
                >
                    &times;
                </span>
            </li>
        )
    })

    return (
        <div className="cart">
            <div className="cart-outer ml-auto relative pl-3 lg:w-40">
                <div className="cart-inner flex items-center">
                    <div
                        className={`cart-icon w-full flex cursor-pointer hover:opacity-75 items-center`}
                        onClick={handleCartDrawer}
                        title="Click to open cart"
                    >
                        <svg
                            className={`w-5`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 150 150"
                        >
                            <path
                                d="M61 129.9c0 5.2-4.1 9.3-9.3 9.3s-9.3-4.1-9.3-9.3c0-5.2 4.1-9.3 9.3-9.3s9.3 4.1 9.3 9.3m52.1-9.3c-5.2 0-9.3 4.1-9.3 9.3 0 5.2 4.1 9.3 9.3 9.3s9.3-4.1 9.3-9.3c0-5.2-4.1-9.3-9.3-9.3m32.8-89.9c-.7-.9-1.7-1.3-2.8-1.3H36.4l-3.2-15.6c-.4-1.7-1.9-3-3.7-3H7.2c-4.8 0-4.8 7.4 0 7.4h19.4l14.3 70.6-5.4 17.7c-.7 2.4 1.1 4.8 3.5 4.8h89.4c4.8 0 4.8-7.4 0-7.4H43.7L47.4 92l86.6-8.6c1.7-.2 3-1.3 3.4-3 10-50.3 10-47.7 8.5-49.7"
                                fill="#fff"
                            />
                        </svg>
                        {numProducts !== 0 && (
                            <div className="cart-items flex items-center">
                                <span className="cart-count text-gray-100 text-xs pl-2 flex">
                                    {numProducts === 1 ? (
                                        <Fragment>
                                            <span className="numProducts">
                                                {numProducts}
                                            </span>
                                            <span className="items hidden sm:block">
                                                &nbsp;item
                                            </span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <span className="numProducts">
                                                {numProducts}
                                            </span>
                                            <span className="items hidden sm:block">
                                                &nbsp;items
                                            </span>
                                        </Fragment>
                                    )}
                                </span>
                                <span className="cart-count-total text-gray-100 text-xs pl-2 hidden sm:block">
                                    {total !== 0 && `($${total})`}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <CartDrawer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                cartList={cartList}
                total={total}
            />
        </div>
    )
})

export default Cart
