import React from 'react'

const ScrollToTopLogo = () => {
    const handleClick = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div
            className="scrolltop-logo cursor-pointer w-16 mt-6"
            title="scroll to top"
        >
            <img
                onClick={handleClick}
                className="header-logo"
                src="https://s3.us-east-2.amazonaws.com/vizualassets/images/vizual_eye.svg"
                onMouseOver={(e) =>
                    (e.currentTarget.src =
                        'https://s3.us-east-2.amazonaws.com/vizualassets/images/vizual_eye_h.svg')
                }
                onMouseOut={(e) =>
                    (e.currentTarget.src =
                        'https://s3.us-east-2.amazonaws.com/vizualassets/images/vizual_eye.svg')
                }
                itemProp="logo"
                alt="Vizual Records logo"
            />
        </div>
    )
}

export default ScrollToTopLogo
