import React from 'react'

const ExternalLinkIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="svg-inline--fa fa-external-link-square fa-w-14 w-3 mx-2"
            data-icon="external-link-square"
            data-prefix="far"
            viewBox="0 0 448 512"
        >
            <path
                fill="currentColor"
                d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 01-6-6V86a6 6 0 016-6h340a6 6 0 016 6v340a6 6 0 01-6 6zm-54-304l-136 .145c-6.627 0-12 5.373-12 12V167.9c0 6.722 5.522 12.133 12.243 11.998l58.001-2.141L99.515 340.485c-4.686 4.686-4.686 12.284 0 16.971l23.03 23.029c4.686 4.686 12.284 4.686 16.97 0l162.729-162.729-2.141 58.001c-.136 6.721 5.275 12.242 11.998 12.242h27.755c6.628 0 12-5.373 12-12L352 140c0-6.627-5.373-12-12-12z"
            ></path>
        </svg>
    )
}

export default ExternalLinkIcon
