import React from 'react'
import { Link } from 'react-router-dom'

import TwitterIcon from '../Icons/TwitterIcon'
import InstagramIcon from '../Icons/InstagramIcon'
import SoundcloudIcon from '../Icons/SoundcloudIcon'
import ScrollToTopLogo from '../ScrollToTop/ScrollToTopLogo'

const year = new Date().getFullYear()

const Footer = React.memo(({ products }) => {
    let releases

    if (products) {
        console.log('products in footer', products)
        const prods = [...products]
        prods.sort((a, b) =>
            a.node.sku > b.node.sku ? 1 : b.node.sku > a.node.sku ? -1 : 0
        )

        console.log('prods', prods)
        releases = prods.map((item, index) => {
            let release = item.node
            let type = release.productCategories.nodes[0].name.toLowerCase()

            let name = release.name
            let catalogNumber = name.substr(0, name.indexOf(' '))
            let title = name.substr(name.indexOf(' ') + 1)
            return (
                <li className="release-link" key={release.id}>
                    <Link to={`/catalog/${type}/${release.slug}`}>
                        <span className="text-sky-500 hover:text-sky-700 text-sm">
                            <span className="release-cat w-12 text-gray-400 numeric-tabular-nums">
                                {catalogNumber}
                            </span>
                            &nbsp;<span className="release-title">{title}</span>
                        </span>
                    </Link>
                </li>
            )
        })
    }

    return (
        <footer className="footer bg-stone-100">
            <div className="inner-footer wrap pt-6 pb-2 px-4 lg:px-0">
                <div className="container">
                    <div className="footer-columns grid lg:grid-cols-footer pb-8">
                        <div className="footer-column mb-4 sm:mb-0">
                            <h3 className="text-lg font-bold text-zinc-700 mb-1">
                                Vizual Releases
                            </h3>
                            <ul className="">{releases}</ul>
                        </div>
                        <div className="footer-column mb-4 sm:mb-0">
                            <h3 className="text-lg font-bold text-zinc-700 mb-1">
                                More
                            </h3>
                            <ul>
                                <li>
                                    <Link to="/about">
                                        <span className="text-sky-500 hover:text-sky-700 text-sm">
                                            About Vizual Records
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/outlets">
                                        <span className="text-sky-500 hover:text-sky-700 text-sm">
                                            Get Vizual Music
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/store">
                                        <span className="text-sky-500 hover:text-sky-700 text-sm">
                                            Vizual Clothing
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                            <h3 className="text-lg font-bold text-zinc-700 mb-1 mt-4">
                                Bits &amp; Bobs
                            </h3>
                            <ul>
                                <li>
                                    <Link to="/faq">
                                        <span className="text-sky-500 hover:text-sky-700 text-sm">
                                            FAQ
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms">
                                        <span className="text-sky-500 hover:text-sky-700 text-sm">
                                            Terms &amp; Privacy
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        <span className="text-sky-500 hover:text-sky-700 text-sm">
                                            Contact Us
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-column mb-4 sm:mb-0">
                            <h3 className="text-lg font-bold text-zinc-700 mb-2">
                                Connect
                            </h3>
                            <ul className="social-links nostyle flex items-center">
                                <li className="twitter-link mr-3">
                                    <a
                                        href="https://twitter.com/vizualrecords"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Vizual Records on Twitter"
                                    >
                                        <TwitterIcon />
                                    </a>
                                </li>
                                <li className="instagram-link mr-3">
                                    <a
                                        href="https://instagram.com/vizualrecords"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Vizual Records on Instagram"
                                    >
                                        <InstagramIcon />
                                    </a>
                                </li>
                                <li className="soundcloud-link">
                                    <a
                                        href="https://soundcloud.com/vizual"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="Vizual Records on Soundcloud"
                                    >
                                        <SoundcloudIcon />
                                    </a>
                                </li>
                            </ul>
                            <ScrollToTopLogo />
                        </div>
                    </div>
                    <div className="footer-bottom pt-4 pb-16">
                        <p className="copyright text-gray-500 text-sm">
                            &copy; 2008&ndash;{year} Vizual Records. All rights
                            reserved.
                        </p>
                    </div>

                    <a rel="me" href="https://mastodon.social/@joshuaiz">Mastodon</a>
                </div>
            </div>
        </footer>
    )
})

export default Footer
