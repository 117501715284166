import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { connectAutoComplete } from 'react-instantsearch-dom'

import Results from './Results'
import SearchIcon from '../Icons/SearchIcon'
import MinusCircleIcon from '../Icons/MinusCircleIcon'

const Autocomplete = ({
    hits,
    currentRefinement,
    refine,
    handleResultClick,
    handleIsSearching,
    isSearching,
}) => {
    let navigate = useNavigate()

    const textInput = useRef(null)

    // if searching and enter key is pressed
    // navigate to first link returned in results
    const onKeyPressHandler = (e) => {
        if (e.key === 'Enter') {
            const firstLink = document.querySelector('ul > li:first-child > a')
            navigate(firstLink)
        }
    }

    useEffect(() => {
        // console.log('search-input', textInput.current)
        if (isSearching) {
            textInput.current.focus()
        }
        return () => null
    }, [isSearching])

    return (
        <div className="search relative w-full h-full">
            <div className="search-wrap flex relative">
                <input
                    type="search"
                    ref={textInput}
                    value={currentRefinement}
                    onChange={(event) => refine(event.currentTarget.value)}
                    className="search-input bg-gray-200 h-full w-full p-2 pl-8 h-16 text-xl text-blue-800 rounded font-semibold"
                    placeholder="Search music and artists"
                    onKeyPress={onKeyPressHandler}
                    autoFocus={true}
                />
                <div className="search-icon absolute left-0 ml-2 text-2xl text-gray-500 mt-3 top-3">
                    <SearchIcon
                        width={'4'}
                        color={'text-gray-500'}
                        cursor={'default'}
                    />
                </div>
                <div
                    className="close-search text-2xl text-gray-500 cursor-pointer absolute -mr-6 right-0 mt-2 pt-1 hover:text-gray-600 top-2"
                    title="Close search"
                    onClick={handleIsSearching}
                >
                    <MinusCircleIcon />
                </div>
            </div>
            <Results
                hits={hits}
                currentRefinement={currentRefinement}
                handleResultClick={handleResultClick}
            />
        </div>
    )
}

const CustomAutocomplete = connectAutoComplete(Autocomplete)

export default CustomAutocomplete
