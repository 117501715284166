import React from 'react'
// import { Prompt } from 'react-router-dom'

import ScrollToTop from '../ScrollToTop/ScrollToTop'
import DownloadIcon from '../Icons/DownloadIcon'

const OrderComplete = ({ isCompleted, downloads }) => {
    return (
        <div className="order-complete">
            <h2 className="text-lg mb-3 text-green-700">
                Thank you for shopping at Vizual Records!
            </h2>
            <p>
                👁‍🗨 Downloads for your order can be found below and also in your
                order confirmation email. Please download all of your files
                before leaving or reloading this page.
            </p>
            {downloads ? (
                <div className="downloads py-4 px-6 bg-gray-100">
                    <h3 className="mb-3 text-lg font-bold text-gray-800">
                        Your downloads:
                    </h3>
                    <ul className="downloads-list nostyle">
                        {downloads.map((download) => {
                            // console.log('download map', download)
                            const d = download.node
                            return (
                                <li
                                    key={d.downloadId}
                                    className="file-download py-3 border-t border-gray-300"
                                >
                                    <a
                                        href={d.download.file}
                                        download={d.download.file}
                                        className="flex hover:border-none items-center"
                                        title={`Download ${d.name}`}
                                        data-file={d.product.productFields.cat}
                                    >
                                        <div className="download-image mr-2 w-8 h-8">
                                            <img
                                                src={
                                                    d.product.image.mediaDetails
                                                        .sizes[1].sourceUrl
                                                }
                                                alt={d.product.image.altText}
                                            />
                                        </div>
                                        <span className="download-name mr-2">
                                            {d.name}
                                        </span>
                                        <i className="download-link icon w-6 h-6 text-xs mr-2">
                                            <DownloadIcon />
                                        </i>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            ) : null}

            <ScrollToTop />
        </div>
    )
}

export default OrderComplete
