import React from 'react'
import MPlayer from './MPlayer'
import { slugify } from './helpers/helpers'
import './assets/styles.css'

const MockPlayer = ({
    playlist,
    products,
    getCurrentAudio,
    productFields,
    productImage,
}) => {
    console.log('products in player', products)
    console.log('playlist in player', playlist)
    return (
        <div className="reaudio">
            {playlist.map((track, index) => {
                const slug = slugify(
                    track.trackName + '-' + track.trackArtist + '-' + track.id
                )
                let product
                let productObj

                if (products) {
                    product = products.filter((item, i) => {
                        return item.name === track.trackName
                    })
                }

                console.log('product in player', product)

                if (product) {
                    productObj = product[0]
                } else {
                    productObj = null
                }

                return (
                    <MPlayer
                        key={track.id}
                        slug={slug}
                        index={index}
                        source={track.source}
                        trackName={track.trackName}
                        trackArtist={track.trackArtist}
                        trackImage={track.trackImage}
                        loop={track.loop}
                        product={productObj}
                        productFields={productFields}
                        productImage={productImage}
                    />
                )
            })}
        </div>
    )
}

export default MockPlayer
