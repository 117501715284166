import React from 'react'
import { Link } from 'react-router-dom'

const Logo = () => {
    return (
        <div className="logo w-12 cursor-pointer" itemProp="logo">
            <Link to="/">
                <img
                    className="header-logo"
                    src="https://s3.us-east-2.amazonaws.com/vizualassets/images/vizual_eye.svg"
                    onMouseOver={(e) =>
                        (e.currentTarget.src =
                            'https://s3.us-east-2.amazonaws.com/vizualassets/images/vizual_eye_h.svg')
                    }
                    onMouseOut={(e) =>
                        (e.currentTarget.src =
                            'https://s3.us-east-2.amazonaws.com/vizualassets/images/vizual_eye.svg')
                    }
                    itemProp="logo"
                    alt="Vizual Records logo"
                />
            </Link>
        </div>
    )
}

export default Logo
